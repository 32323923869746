import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { ProjectAssignedUserService } from '../../../../services/project/project-assigned-user.service';
import { ProjectService } from '../../../../services/project/project.service';
import { UserService } from '../../../../services/user/user.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { UserInviteService } from '../../../../services/user/user-invite.service';
import { forkJoin } from 'rxjs';
import { ClientSubClientService } from '../../../../services/client/client-subclient.service';

@Component({
	selector: 'app-sub-user-info',
	templateUrl: './sub-user-info.component.html',
	styleUrls: ['./sub-user-info.component.scss'],
})
export class SubUserInfoComponent {
	@Input() itemsPerSlide = 5;
	selectedSubClientList = [];
	@Output() subUserDetail: any = new EventEmitter();
	subClientUserList = [];
	modalNewInviteUserModal: BsModalRef;
	closeIcon = FlocksyUtil.closeIcon;
	valForm: FormGroup;
	addSubUserImg = FlocksyUtil.addSubUserImg;
	invitedUserList = [];
	permissions = [
		{
			label: 'Manage All Projects',
			id: 'can_manage_all_projects',
		},
		{
			label: 'View All Projects',
			id: 'can_view_projects',
		},
		{
			label: 'Manage Their Projects',
			id: 'can_manage_own_projects',
		},
		{
			label: 'View All & Manage Their Projects',
			id: 'can_view_manage_own_projects',
		},
	];
	permissionsData = {
		permissions: {
			can_view_projects: false,
			can_manage_all_projects: false,
			can_manage_own_projects: false,
			can_view_manage_own_projects: false,
		},
	};
	isInviteValidationShow: boolean;
	isSkeletonLoading = true;
	originalUsersList = [];
	@Input() assignedUsers = [];

	constructor(
		private _projectService: ProjectService,
		private _userService: UserService,
		private _localStorageService: LocalStorageService,
		private _formBuilder: FormBuilder,
		private _toasterService: ToasterService,
		public _userInviteService: UserInviteService,
		private _bsModalService: BsModalService,
		private __clientSubClientService: ClientSubClientService
	) {
		this.initForm();
		// this.subClientUserList.push({});
		this.retrievExistingSubClients();
		// this.retrieveSubClientUser();
	}

	initForm() {
		this.valForm = this._formBuilder.group({
			name: [null, Validators.required],
			email: [null, [Validators.required, Validators.email]], // Using built-in Validators.email for simplicity
			permissions: ['', Validators.required],
		});
	}

	onPermissionsSelected(permissionName) {
		if (this.permissionsData.permissions.hasOwnProperty(permissionName)) {
			Object.keys(this.permissionsData.permissions).forEach((key) => {
				this.permissionsData.permissions[key] = false;
			});
			this.permissionsData.permissions[permissionName] = true;
		}
	}

	retrievExistingSubClients() {
		this.__clientSubClientService.index(this._localStorageService.getUserId()).subscribe(
			(data) => {
				console.log(this.assignedUsers);
	
				this.originalUsersList = data.data;
				this.subClientUserList = [];
				this.selectedSubClientList = [];
	
				// Loop through originalUsersList and update both subClientUserList and selectedSubClientList
				this.originalUsersList.forEach((subClient) => {
					if (subClient?.permissions) {
						// Add globalPermissions and permissionUpdated fields
						let subClientWithGlobalPermissions = {
							...subClient,
							globalPermissions: { ...subClient.permissions }, // Clone permissions
							permissionUpdated: false // Initialize permissionUpdated as false
						};
	
						// Push to subClientUserList and selectedSubClientList
						this.subClientUserList.push(subClientWithGlobalPermissions);
						this.selectedSubClientList.push(subClientWithGlobalPermissions);
					}
				});
	
				// Check if assignedUsers array contains any values
				if (this.assignedUsers && this.assignedUsers.length > 0) {
					// If assignedUsers is not empty, set has_permission based on matching IDs
					this.selectedSubClientList.forEach((subClient) => {
						// Check if the subClient id is in the assignedUsers array
						subClient.has_permission = this.assignedUsers.includes(subClient.id) ? 1 : 0;
					});
				} else {
					// If assignedUsers is empty, use the default logic
					this.selectedSubClientList.forEach((subClient) => {
						subClient.has_permission = subClient.permissions.can_manage_own_projects ? 0 : 1;
					});
				}
	
				// Insert "Add user" card after every 4th card
				const addUserCard = { isAddUserCard: true }; 
				const updatedList = [];
	
				for (let i = 0; i < this.subClientUserList.length; i++) {
					updatedList.push(this.subClientUserList[i]);
					if ((i + 1) % 4 === 0) {
						updatedList.push(addUserCard);
					}
				}
	
				if (updatedList.length % 5 !== 0) {
					updatedList.push(addUserCard);
				}
	
				this.subClientUserList = updatedList;
	
				// Emit the selected sub-client list
				this.subUserDetail.emit(this.selectedSubClientList);
	
				this.isSkeletonLoading = false;
			}
		);
	}	
	
	retrieveSubClientUser(permissions) {
		this._userInviteService.index(this._localStorageService.getUserId()).subscribe(
			(inviteList) => {
				console.log(inviteList);
				
				this.isSkeletonLoading = true;
	
				this.subClientUserList = [...this.originalUsersList];
	
				let inviteUser = [];
				const processedInviteList = inviteList.data.map((obj) => ({
					...obj,
					client_list_type: 1,
				}));
	
				this.invitedUserList.forEach((resData) => {
					let index = processedInviteList.findIndex((res) => resData?.email == res?.email);
					if (index != -1) {
						inviteUser.push(processedInviteList[index]);
					}
				});
	
				if (inviteUser.length > 0) {
					const lastIndex = inviteUser.length - 1;
					inviteUser[lastIndex] = {
						...inviteUser[lastIndex],
						permissions: JSON.parse(JSON.stringify(permissions)),
						invited: true
					};
				}
	
				if (inviteUser.length > 0) {
					const lastIndex = inviteUser.length - 1;
					this.originalUsersList = [inviteUser[lastIndex], ...this.originalUsersList];
					this.subClientUserList = [inviteUser[lastIndex], ...this.subClientUserList];
				}
	
				const updatedList = [];
				const addUserCard = { isAddUserCard: true }; 
	
				for (let i = 0; i < this.subClientUserList.length; i++) {
					updatedList.push(this.subClientUserList[i]);
					if ((i + 1) % 4 === 0) {
						updatedList.push(addUserCard);
					}
				}
	
				if (updatedList.length % 5 !== 0) {
					updatedList.push(addUserCard);
				}
	
				this.subClientUserList = updatedList;
				this.selectedSubClientList = [];
	
				// Process subClientUserList and add globalPermissions and permissionUpdated fields
				this.subClientUserList.forEach((subClient) => {
					if (subClient?.permissions) {
						let subClientWithPermissions = {
							...subClient,
							globalPermissions: { ...subClient.permissions }, // Clone permissions
							permissionUpdated: false, // Initialize permissionUpdated as false
							has_permission_enabled: subClient.permissions.can_manage_own_projects ? 0 : 1
						};
	
						// Add to both lists
						this.selectedSubClientList.push(subClientWithPermissions);
					}
				});
	
				console.log(this.subClientUserList);
				
				this.isSkeletonLoading = false;
			}
		);
	}
	
	submitForm($ev, value: any) {
		this.isSkeletonLoading = true;
		this.isInviteValidationShow = true;
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		if (!this.valForm.valid) {
			return false;
		}
		
		// Create a deep copy of permissions
		let copiedPermissions = JSON.parse(JSON.stringify(this.permissionsData.permissions));
	
		let oParam = {
			name: this.valForm.value.name,
			email: this.valForm.value.email,
			permissions: copiedPermissions,  // Use the copied permissions
		};
	
		this._userService
			.storeUsersInvite(this._localStorageService.getUserId(), oParam)
			.subscribe((data) => {
				this.invitedUserList.push(oParam);
				this.valForm.reset();
				this.modalNewInviteUserModal.hide();
				this._toasterService.pop('success', 'Invite sent!', 'Go team!');
				this.retrieveSubClientUser(oParam.permissions);  // Pass the copied permissions
				this.isInviteValidationShow = false;
			});
	}	

	initNewInviteUserModal(template: TemplateRef<any>) {
		this.isInviteValidationShow = false;
		this.modalNewInviteUserModal = this._bsModalService.show(
			template,
			Object.assign({}, {}, {})
		);
	}

	selectSubClient(index) {
		if (this.selectedSubClientList && this.subClientUserList[index]) {
			let findIndex = this.selectedSubClientList.findIndex((res) => {
				return res.id === this.subClientUserList[index].id;
			});
			if (findIndex !== -1) {
				this.subClientUserList[findIndex].has_permission = 1;
				this.selectedSubClientList[findIndex].has_permission = 1;
				this.subUserDetail.emit(this.selectedSubClientList);
			}
		}
	}

	unSelectSubClient(index) {
		if (this.selectedSubClientList && this.subClientUserList[index]) {
			let findIndex = this.selectedSubClientList.findIndex((res) => {
				return res.id === this.subClientUserList[index].id;
			});
	
			if (findIndex !== -1) {
				// Reset has_permission to 0
				this.subClientUserList[findIndex].has_permission = 0;
				this.selectedSubClientList[findIndex].has_permission = 0;
	
				// Reset permissions to globalPermissions
				this.subClientUserList[findIndex].permissions = { 
					...this.subClientUserList[findIndex].globalPermissions 
				};
				this.selectedSubClientList[findIndex].permissions = { 
					...this.selectedSubClientList[findIndex].globalPermissions 
				};
	
				// Set permissionUpdated to false
				this.subClientUserList[findIndex].permissionUpdated = false;
				this.selectedSubClientList[findIndex].permissionUpdated = false;
	
				// Emit the updated selectedSubClientList
				this.subUserDetail.emit(this.selectedSubClientList);
			}
		}
	}	
	
	changePermission(user, type) {
		const updatePermissions = (user, type) => {
			// Reset all permissions to false
			user.permissions.can_manage_all_projects = false;
			user.permissions.can_manage_own_projects = false;
			user.permissions.can_view_manage_own_projects = false;
			user.permissions.can_view_projects = false;
	
			// Set the relevant permission based on type
			if (type === 'manage') {
				user.permissions.can_manage_all_projects = true;
			} else if (type === 'view') {
				user.permissions.can_view_projects = true;
			}
	
			// Mark the permission as updated
			user.permissionUpdated = true;
		};
	
		// Update permissions in both selectedSubClientList and subClientUserList
		[this.selectedSubClientList, this.subClientUserList].forEach(list => {
			const index = list.findIndex(item => item.id === user.id);
			if (index !== -1) {
				updatePermissions(list[index], type);
			}
		});
	
		// Emit the updated lists
		this.subUserDetail.emit(this.selectedSubClientList);
		console.log(this.selectedSubClientList);
	}	
	
}
