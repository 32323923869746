import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { CustomHttpService } from '@services/custom.http.service';
import { LocalStorageService } from '@services/localstorage.service';
import { GenericHttpResponse, WhiteLabelledRequest, WhiteLabelledUser } from '@services/interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  appTitle$ = new BehaviorSubject<string>('');
  defaultFavicon: string = 'assets/img/custom-domain/custom_domain_default_favicon.png';
  defaultLogo: string = 'assets/img/custom-domain/custom_domain_default_logo.png';
  whiteLabelledUser$ = new BehaviorSubject<WhiteLabelledUser>({} as WhiteLabelledUser);
  currentUser = this._localStorageService.getCurrentUser();
  constructor(
    private _httpService: CustomHttpService,
    private _localStorageService: LocalStorageService,
    private _router: Router,
  ) { }

  init() {
    this.processFaviconAndTitle("", "");
    this.processClientDomainDetails();
  }

  addCustomDomain(data: WhiteLabelledRequest) {
    data = this._httpService.removeEmpty(data);
    return this._httpService.post(API_CONSTANTS.CUSTOM_DOMAIN, data);
  }

  updateCustomDomain(id: number, data: Partial<WhiteLabelledRequest>) {
    data = this._httpService.removeEmpty(data);
    return this._httpService.put<GenericHttpResponse<WhiteLabelledUser>>(API_CONSTANTS.CUSTOM_DOMAIN+`/${id}`, data);
  }

  getWhiteLabelDetails(userId: string) {
    return this._httpService.get<GenericHttpResponse<WhiteLabelledUser>>(`${API_CONSTANTS.API_ENDPOINT_URL}/users/${userId}/custom-domains`);
  }

  getClientDomainDetails() {
    return this._httpService.get<GenericHttpResponse<WhiteLabelledUser>>(API_CONSTANTS.CUSTOM_DOMAIN);
  }

  private processClientDomainDetails() {
    this.getClientDomainDetails().subscribe((response) => {
      this.setWhiteLabelledUser(response.data);
      if(!response.data.is_active) {
        // Check without changing route -> Instead of login show error page
        this._router.navigate(['/inactive']);
      }
      else if(!response.data.is_domain_propagated) {
        // Different message for domain_propagate
        this._router.navigate(['/inactive']);
      }
      this.processFaviconAndTitle(response.data?.favicon_document?.full_path??this.defaultFavicon, response.data.business_name);
    },
    (err) => {
      this._router.navigate(['/inactive']);
      throw new Error(err);
    })
  }

  private processFaviconAndTitle(icon:string, title:string): void {
    //Keep dummy until change
    const favIcon: HTMLLinkElement = document.querySelector("#_customFaviconIcon");
    if(favIcon) {
      favIcon.href = icon;
    }
    this.setAppTitle(title);
  }

  setFlocksyFavicon(): void {
    const favIcon: HTMLLinkElement = document.querySelector("#_customFaviconIcon");
    if(favIcon) {
      favIcon.href = "assets/img/custom-domain/favicon.png";
    }
  }

  getAppTitle() {
    return this.appTitle$.asObservable();
  }

  setAppTitle(title: string) {
    this.appTitle$.next(title);
  }

  setIsWhiteLabelledUser(value: boolean) {
    this._localStorageService.setItem('is_white_labeled', JSON.stringify(value));
  }

  isWhiteLabelledUser(): boolean {
    return JSON.parse(this._localStorageService.getItem('is_white_labeled', "false"));
  }

  setWhiteLabelledUser(user: WhiteLabelledUser) {
    this.whiteLabelledUser$.next(user);
    this._localStorageService.setItem('white_labeled_user', JSON.stringify(user));
  }

  getWhiteLabelledUser$(): Observable<WhiteLabelledUser> {
    return this.whiteLabelledUser$.asObservable();
  }

  getWhiteLabelledUser():WhiteLabelledUser {
    return JSON.parse(this._localStorageService.getItem('white_labeled_user', "false"));
  }

  showLoader(): void {
    let progress = document.createElement("div");
    progress.classList.add('preloader-progress');
    let progressBar = document.createElement("div");
    progressBar.classList.add('preloader-progress-bar');
    progress.appendChild(progressBar);
    const loader: HTMLElement = document.querySelector(".preloader");
    if(loader) {
      loader.appendChild(progress);
    }
  }

  testDomain() {
    return this._httpService.get<GenericHttpResponse<{is_domain_propagated: number}>>(API_CONSTANTS.CUSTOM_DOMAIN+'/propagated');
  }

  checkAllowedWhiteLabel():boolean {
    this.currentUser = this._localStorageService.getCurrentUser();
    if(this.currentUser && this.currentUser?.user_plans && this.currentUser?.user_plans.length > 0){
      for(let single of this.currentUser?.user_plans){
        if(single?.plan?.has_allow_white_label === 1) {
          return true;
        }
      }
    }
    return false;
  }

}
