import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { LocalStorageService } from './../localstorage.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { MessageSharedService } from '../message/message-shared.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class CommonService implements OnInit {
	isReloadBillingInfoTextShown = new BehaviorSubject<boolean>(false);
	formSubmitted = new EventEmitter<string>();
	actionTriggered = new EventEmitter<object>();

	profilePictureChanged = new EventEmitter<string>();
	loginBeanNameChanged = new EventEmitter<string>();
	unassignedChatsUpdated = new EventEmitter<object>();
	messageChatsUpdated = new EventEmitter<object>();
	notificationCountUpdated = new EventEmitter<object>();
	addedNewStripeCard = new EventEmitter<object>();
	clearEmojiBtnInterval = new EventEmitter<boolean>();
	subClientLoggedInInfo = new ReplaySubject<any>();
	currentInterval;
	docElement;

	constructor(
		public _localStorageService: LocalStorageService,
		private _router: Router,
		private _analyticsService: AnalyticsService,
		private readonly _messageSharedService: MessageSharedService
	) {}

	ngOnInit() {}

	redirectUser(packageId?: any, packageInfo?: any) {
		let hasMarketplaceAccess =
			this._localStorageService.hasMarketplaceAccess();
		if (this._localStorageService.isClientUser()) {
			if (
				this._localStorageService.getClientPlanID() != 0 ||
				hasMarketplaceAccess
			) {
				if (packageId && hasMarketplaceAccess) {
					this._router.navigate(['/projects/create'], {
						queryParams: {
							package_id: packageId,
							project_type_id: packageInfo?.project_type_id,
							project_title_id: packageInfo?.project_title_id,
							project_title: packageInfo?.project_title_name,
						},
					});
				} else if (!hasMarketplaceAccess && packageId) {
					this._router.navigate(['/projects/create'], {
						queryParams: {
							package_id: packageId,
							project_type_id: packageInfo?.project_type_id,
							project_title_id: packageInfo?.project_title_id,
							project_title: packageInfo?.project_title_name,
						},
					});
				} else {
					this._router.navigate(['/account-activity']);
				}
			} else {
				if (!this._localStorageService.isUpdatedBilling()) {
					this._router.navigate(['/plans/billing-info']);
					this._analyticsService.setRouteData(71, 178);
				} else {
					if (packageInfo && packageId) {
						this._router.navigate(['/projects/create'], {
							queryParams: {
								package_id: packageId,
								project_type_id: packageInfo?.project_type_id,
								project_title_id: packageInfo?.project_title_id,
								project_title: packageInfo?.project_title_name,
							},
						});
					} else {
						this._analyticsService.setRouteData(14, 26);
						this._router.navigate(['/plans']);
					}
				}
			}
		} else if (this._localStorageService.isSubClientUser()) {
			if (this._localStorageService.getParentClientPlanID() != 0) {
				if (packageId && hasMarketplaceAccess) {
					/* this._router.navigate(['/projects/create'], {
						queryParams: {
							package_id: packageId,
							project_type_id: packageInfo?.project_type_id,
							project_title_id: packageInfo?.project_title_id,
							project_title: packageInfo?.project_title_name,
						},
					}); */
					this._router.navigate(['/account-activity']);
				} else {
					this._router.navigate(['/account-activity']);
				}
			} else {
				this._router.navigate(['/plans']);
				const prevPage =
					this._localStorageService.getItem('prevPageId');
				this._analyticsService.setRouteData(14, 26);
			}
		} else if (this._localStorageService.isDesignerUser()) {
			this._router.navigate(['/dashboard']);
		} else if (this._localStorageService.isProjectManagerUser()) {
			this._router.navigate(['/projects/active']);
			this._analyticsService.setRouteData(19, 47);
		} else if (this._localStorageService.isAdminUser()) {
			this._router.navigate(['/projects/active']);
			this._analyticsService.setRouteData(19, 47);
		} else {
			this._router.navigate(['/login']);
		}
	}

	copyToClipboard(string) {
		let textarea;
		let result;

		try {
			textarea = document.createElement('textarea');
			textarea.setAttribute('readonly', true);
			textarea.setAttribute('contenteditable', true);
			textarea.style.position = 'fixed';
			textarea.value = string;

			document.body.appendChild(textarea);

			textarea.focus();
			textarea.select();

			const range = document.createRange();
			range.selectNodeContents(textarea);

			const sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);

			textarea.setSelectionRange(0, textarea.value.length);
			result = document.execCommand('copy');
		} catch (err) {
			console.error(err);
			result = null;
		} finally {
			document.body.removeChild(textarea);
		}

		/* manual copy fallback using prompt */
		if (!result) {
			const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
			const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
			result = prompt(`Press ${copyHotkey}`, string);
			if (!result) {
				return false;
			}
		}
		return true;
	}

	async emojiBtn(className, idName) {
		const emojiUploadElement = document.querySelector('.' + className);
		if (
			emojiUploadElement &&
			!emojiUploadElement?.parentElement?.innerHTML.includes(idName)
		) {
			this.clearEmojiBtnInterval.emit(true);
			emojiUploadElement?.setAttribute('id', idName);
			if (emojiUploadElement) {
				emojiUploadElement.addEventListener('click', (event: any) => {
					if (
						event.target.parentElement.parentElement.innerHTML.includes(
							className
						)
					)
						this._messageSharedService.insertEmoji.next({
							isEmojiEditorToShown: true,
							emojiEditorClass: className,
						});
				});
			}
		}
	}

	hideUnUsedEmojiFromDom() {
		clearInterval(this.currentInterval);
		this.currentInterval = setInterval(() => {
			this.docElement = document.querySelectorAll<HTMLElement>(
				'[aria-label="☺️, relaxed"]'
			);
			this.docElement?.forEach((data) => {
				data.style.display = 'none';
			});
			if (this.docElement) {
				this.clearInterval(this.currentInterval);
			}
		}, 100);
	}

	clearInterval(activeInterval) {
		clearInterval(activeInterval);
	}

	onlyNumeric(event) {
		if (event.charCode >= 48 && event.charCode <= 57) {
			return;
		} else {
			event.preventDefault();
			return;
		}
	}

	removeExtraWhitespace(str) {
		while (str.indexOf('\t') > -1) {
			str = str.replace('\t', ' ');
		}
		while (str.indexOf('  ') > -1) {
			str = str.replace('  ', ' ');
		}
		str = str.replace(/\s*,\s*/g, ', ').trim();
		return str;
	}

	copyHTMlToClipboard(str) {
		const listener = function (ev) {
			ev.preventDefault();
			ev.clipboardData.setData('text/html', str);
			ev.clipboardData.setData('text/plain', str);
		};
		document.addEventListener('copy', listener);
		document.execCommand('copy');
		document.removeEventListener('copy', listener);
	}

	formatHtmlStr(offerInfo, activatedRoute, clientBadgeInfo) {
		let offerInfoStr = offerInfo.split('|');
		offerInfoStr[0] = `<a href="${activatedRoute}">${offerInfoStr[0].trim()}</a> ${
			clientBadgeInfo ? '| ' + clientBadgeInfo : ''
		}`;

		let str1 = offerInfoStr[1].split(':');
		str1[0] = `<b>${str1[0]}:</b>`;
		offerInfoStr[1] = str1.join(' ');

		let str2 = offerInfoStr[2].split(':');
		str2[0] = `<b>${str2[0]}:</b>`;
		offerInfoStr[2] = str2.join(' ');

		let str3 = offerInfoStr[3].split(':');
		str3[0] = `<b>${str3[0]}:</b>`;
		offerInfoStr[3] = str3.join(' ');

		let str4 = offerInfoStr[4].split(':');
		str4[0] = `<b>${str4[0]}:</b>`;
		offerInfoStr[4] = str4.join(' ');

		let str5 = offerInfoStr[5].split(':');
		str5[0] = `<b>${str5[0]}:</b>`;
		offerInfoStr[5] = str5.join(' ');

		offerInfoStr = offerInfoStr.join('|');

		return offerInfoStr;
	}

	isBoltIconPlan(planId) {
		return FlocksyUtil.boltIconPlanIds.includes(planId);
	}

	isMarketPlaceOnlyUser(): boolean {
		return (
			this._localStorageService.hasMarketplaceAccess() &&
			!this._localStorageService.getCurrentUser()['plan_id']
		);
	}

	isShowHolidayBanner(holidayDate): boolean {
		const currentDate = new Date();
		return (
			currentDate.getFullYear() === holidayDate.getFullYear() &&
			currentDate.getMonth() === holidayDate.getMonth() &&
			currentDate.getDate() === holidayDate.getDate()
		);
	}

	async getHeaders() {
		const headers: any = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};

		const accessToken = await this._localStorageService.getAccessToken();
		if (accessToken) {
			headers['Authorization'] = `Bearer ${accessToken}`;
		}
		return headers;
	}
	

	onlyNumericValueValidator(control: AbstractControl) {
		const value = control.value;
		if (/[a-zA-Z]/.test(value)) {
			return { containsAlphabets: true };
		}
	}

	getSubscriptionBtnInfo(plansInfo: any): any {
		return plansInfo.every((plan) => {
			if (
				plan?.plan?.is_legacy_plan == 2 ||
				(plan?.plan?.status !== 1 && !plan?.plan?.is_active_upgrade) ||
				plan.plan.billing_period !== plansInfo[0].plan.billing_period
				 
			) {
				// plan is legacy
				return false; // Don't show the button if any plan doesn't meet the conditions
			}
			return true; // Show the button if all plans meet the conditions
		});
	}
}

// status != 1 && is_legacy_plan == 2
