<div class="create-project-wrapper" *ngIf="isSkeletonLoading">
	<div
		class="row m-0"
		*ngIf="
			(oProject?.is_category_plan != false || hasMarketplaceAccess) &&
			!isOverlay
		">
		<div class="new-project-title font-36 mb-4">
			{{
				action !== 'update-brief'
					? 'New Project'
					: category?.projectTitle
					? category?.projectTitle
					: marketplaceCategory?.projectTitle
			}}
		</div>
	</div>
	<div class="font-16 mb-4" #scrollToCategoryMessage>
		<ng-container *ngIf="action !== 'update-brief'">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disabled-text-wrapper p-3 tab-headings"
						[ngClass]="{
							'nav-link active text-dark-grey': !isMarketplaceTab
						}"
						(click)="isEdited.emit(false); switchTab(false)">
						Subscription
					</button>
				</li>
				<li
					class="nav-item"
					*ngIf="hasMarketplaceAccess && !isSubClientUser">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disabled-text-wrapper p-3 tab-headings"
						[ngClass]="{
							'nav-link active text-dark-grey': isMarketplaceTab
						}"
						(click)="isEdited.emit(false); switchTab(true)">
						Marketplace
					</button>
				</li>
			</ul>
		</ng-container>
	</div>
	<section>
		<div
			class="alert alert-success m-2"
			*ngIf="
				oProject?.is_category_plan == false &&
				!hasMarketplaceAccess &&
				action == 'start-project'
			">
			<div class="row">
				<div class="col-auto">
					<em class="fa-exclamation-triangle fas"></em>
				</div>
				<div class="col pl-0">
					<span>
						<button
							class="btn btn-secondary"
							type="button"
							(click)="
								initArchiveProjectModal(
									archiveProjectModal,
									oProject
								);
								_analyticsService.setOverlayTrackData(
									21,
									65,
									oProject.project_id,
									2
								)
							"
							*ngIf="
								oProject &&
								(isClientUser ||
									(isSubClientUser &&
										userId == oProject?.created_by)) &&
								(oProject?.project_status_id == 1 ||
									oProject?.project_status_id == 3 ||
									oProject?.project_status_id == 5) &&
								!oProject?.archived_by
							">
							<span class="mr-2 ml-2">Archive Project</span>
						</button>
					</span>
				</div>
			</div>
		</div>
		<div
			[ngClass]="
				hasMarketplaceAccess &&
				oProjectsData?.project?.concept_id !=
					projectConcepts?.marketplace &&
				!isSubClientUser &&
				!isMarketplaceTab &&
				!(
					authSeamLessData?.plan_activated &&
					isUpdatedBilling &&
					!isUserOnPausePlan
				)
					? 'disable-category'
					: ''
			"
			*ngIf="oProject?.is_category_plan != false || hasMarketplaceAccess">
			<div>
				<div
					class="alert alert-info clickable"
					*ngIf="isOperationalClientUser && isDemoPlan"
					(click)="initDemoSignupOverlay()">
					Any projects created will be moved to your pending queue
					until your account is upgraded. Upgrade now to have this
					project activated and worked on
				</div>
				<div
					class="text-center"
					*ngIf="
						isAdminUser || (isProjectManagerUser && !bCopyBrief)
					">
					<button
						class="btn btn-lg"
						[ngClass]="{ 'btn-success': statusForAdmin == 'all' }"
						(click)="adminFilter('all')">
						Active & Inactive
					</button>
					<button
						class="btn btn-lg"
						[ngClass]="{
							'btn-success': statusForAdmin == 'active'
						}"
						(click)="adminFilter('active')">
						Active
					</button>
				</div>
			</div>
			<div [class.position-relative]="action === 'update-brief'">
				<ng-container *ngIf="action === 'update-brief'">
					<div
						[style.z-index]="2"
						class="position-absolute top-0 left-0 h-100 w-100 transparent cursor-disabled"></div>
				</ng-container>
				<div class="my-2 text-color-gray">
					<span>
						Describe the type of project you are starting
						<em
							*ngIf="action !== 'update-brief'"
							class="fa fa-info-circle clickable ml-1 f-18"
							[popover]="projectTooltip"
							triggers="mouseenter:mouseleave click"
							placement="right"
							(click)="$event.stopPropagation()"></em>
						<ng-template #projectTooltip>
							Choose the type of work you need done. If your
							project requires more than one of these, please
							create separate project briefs.
						</ng-template>
					</span>
				</div>
				<div
					[ngClass]="
						(legacyBrief ||
							(!category['titleName'] &&
								!isMarketplaceTab &&
								category['projectTitle']) ||
							(!marketplaceCategory['titleName'] &&
								isMarketplaceTab &&
								marketplaceCategory['projectTitle'])) &&
						!isMarketplaceTab
							? 'mb-2'
							: 'mb-4'
					">
					<input
						*ngIf="!isMarketplaceTab"
						#myInput
						type="text"
						(input)="filterData()"
						[(ngModel)]="category.searchableText"
						class="form-control search-category"
						[disabled]="
							(legacyBrief ||
								(!category['titleName'] &&
									!isMarketplaceTab &&
									!!category['projectTitle'])) &&
							!isMarketplaceTab
						" />
					<input
						#myInput
						*ngIf="isMarketplaceTab"
						type="text"
						(input)="filterData()"
						[(ngModel)]="marketplaceCategory.searchableText"
						class="form-control search-category"
						placeholder="Start typing and select a category"
						[disabled]="
							legacyBrief ||
							(!marketplaceCategory['titleName'] &&
								marketplaceCategory['projectTitle'].trim()
									.length) ||
							(oSelectedType && oSelectedTitle)
						" />
				</div>

				<div class="position-relative">
					<div
						class="d-inline-block sort-container custom-select-ngx position-absolute">
						<ngx-select
							class="text-muted clickable"
							[noAutoComplete]="true"
							[allowClear]="false"
							[items]="sortingOptions"
							optionValueField="id"
							optionTextField="label"
							placeholder="Sort by"
							(selectionChanges)="
								onSortChange($event)
							"></ngx-select>
					</div>
				</div>

				<div *ngIf="!isMarketplaceTab" class="accordion-wrapper">
					<accordion>
						<accordion-group #group>
							<button
								class="btn btn-link clearfix text-start btn-expand-categories"
								accordion-heading
								type="button">
								<div class="d-inline-block">
									See all categories
								</div>
								<div
									class="d-inline-block ml-2 custom-chevron"
									[ngClass]="{
										down: !group?.isOpen,
										up: group?.isOpen
									}"></div>
							</button>
							<form [formGroup]="filterCategoriesForm">
								<div
									class="d-flex flex-wrap align-items-center">
									<div class="d-flex align-items-center">
										<app-checkbox
											(change)="filterByCategories('seeAll');"
											formControlName="seeAll"
											id="seeAll"></app-checkbox>
										<label
											class="category-label d-inline-block"
											for="seeAll">
											See all
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="graphicDesign"
											id="graphicDesign"></app-checkbox>
										<label
											class="category-label"
											for="graphicDesign">
											Graphic Design
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="copywriting"
											id="copywriting"></app-checkbox>
										<label
											class="category-label"
											for="copywriting">
											Copywriting
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="motionGraphics"
											id="motionGraphics"></app-checkbox>
										<label
											class="category-label"
											for="motionGraphics">
											Motion Graphics
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="videoEditing"
											id="videoEditing"></app-checkbox>
										<label
											class="category-label"
											for="videoEditing">
											Video Editing
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="websiteDevelopment"
											id="websiteDevelopment"></app-checkbox>
										<label
											class="category-label"
											for="websiteDevelopment">
											Website Development
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="customIllustrations"
											id="customIllustrations"></app-checkbox>
										<label
											class="category-label"
											for="customIllustrations">
											Custom Illustrations
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="voiceOvers"
											id="voiceOvers"></app-checkbox>
										<label
											class="category-label"
											for="voiceOvers">
											Voice-Overs
										</label>
									</div>
									<div>
										<app-checkbox
											formControlName="virtualAssistants"
											id="virtualAssistants"></app-checkbox>
										<label
											class="category-label"
											for="virtualAssistants">
											Virtual Assistants
										</label>
									</div>
									<div class="position-relative">
										<app-checkbox
											formControlName="templates"
											id="templates"></app-checkbox>
										<label
											class="category-label"
											for="templates">
											Templates
										</label>
										<div
											class="position-absolute item-new-category">
											NEW!
										</div>
									</div>
								</div>
							</form>
						</accordion-group>
					</accordion>
				</div>

				<div class="col-md-12" *ngIf="oDefs">
					<div
						*ngIf="
							oDefs &&
							oDefs?.titles &&
							oSelectedType &&
							oSelectedTitle &&
							bDisableType &&
							bDisableTitle
						">
						<h3 class="text-success">
							{{ oSelectedType?.title }} Project For
							{{ oSelectedTitle?.title }}
						</h3>
					</div>
					<div class="clearfix"></div>
					<div
						class="alert alert-info mb-0"
						role="alert"
						*ngIf="
							oQueryString &&
							oQueryString?.parent_action &&
							oQueryString?.parent_action == 1
						">
						You are creating a new project. Once you submit it, your
						previous project will close out and this one will start.
					</div>
				</div>
				<div
					[ngStyle]="{
						'margin-top': isMarketplaceTab ? '70px' : '0px'
					}"
					class="sub-category-info-wrapper mb-4"
					#scrollToInvalidField>
					<div>
						<carousel
							*ngIf="oDefs?.titles?.length; else carouselSkeleton"
							[interval]="0"
							[itemsPerSlide]="itemsPerSlide"
							[showIndicators]="false"
							[noWrap]="true"
							(slideRangeChange)="projectSlideChange($event)">
							<ng-container *ngIf="selectedProjectTitle">
								<div [style.width.%]="100 / itemsPerSlide">
									<div class="slide card active mx-3">
										<div
											class="position-absolute w-100 px-3 py-1 d-flex justify-content-end align-items-center">
											<app-true-icon
												[type]="
													'selected'
												"></app-true-icon>
										</div>
										<div class="card-body text-center">
											<div
												class="subcategory-text-wrapper px-2">
												<p class="mb-0">
													{{
														selectedProjectTitle?.title
													}}
												</p>
											</div>
											<div
												class="disabled-text-wrapper mx-2 category-text-wrapper font-12">
												{{
													selectedProjectTitle?.type
														?.title
												}}
											</div>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-container
								*ngFor="
									let slidez of oDefs['titles'];
									let i = index
								">
								<slide
									*ngIf="
										selectedProjectTitle?.project_title_id !=
										slidez?.project_title_id
									"
									class="m-0 relative-container">
									<div
										class="position-relative project-card h-auto slide card mx-3 mb-0 clickable my-3"
										[ngClass]="{
											active:
												(!slidez.isTemplate &&
													slidez?.project_title_id +
														'prj' ===
														category['selected']) ||
												(slidez?.isTemplate &&
													slidez?.id + 'tmp' ===
														category['selected']) ||
												slidez?.project_title_id ===
													marketplaceCategory[
														'selectedTitleId'
													],
											'template-background':
												slidez?.isTemplate,
											'my-3':
												(legacyBrief ||
													(!category['titleName'] &&
														!isMarketplaceTab &&
														category[
															'projectTitle'
														]) ||
													(!marketplaceCategory[
														'titleName'
													] &&
														isMarketplaceTab &&
														marketplaceCategory[
															'projectTitle'
														])) &&
												!isMarketplaceTab
										}"
										(click)="
											isEdited.emit(true);
											isMarketplaceTab
												? onMarketplaceNextTitle(slidez)
												: onSelectProjectTitle(
														slidez,
														i
												  )
										">
										<div
											class="subcategory-selected-icon"
											*ngIf="
												(!slidez.isTemplate &&
													slidez?.project_title_id +
														'prj' ===
														category['selected']) ||
												(slidez?.isTemplate &&
													slidez?.id + 'tmp' ===
														category['selected']) ||
												slidez?.project_title_id ==
													marketplaceCategory[
														'selectedTitleId'
													]
											">
											<app-true-icon
												*ngIf="!slidez?.isTemplate"
												[type]="
													'selected'
												"></app-true-icon>
											<img
												*ngIf="slidez?.isTemplate"
												src="assets/img/projects-templates/template-active-icon.svg"
												alt="icon" />
										</div>
										<div class="text-center mt-4">
											<img
												*ngIf="!isMarketplaceTab"
												[src]="
													slidez?.isTemplate
														? '/assets/img/projects-templates/pencil_man.svg'
														: 'assets/img/categories-svg/' + slidez?.project_title_id + '.svg'
												"
												alt="icon"
												height="60px" />
										</div>
										<div class="card-body text-center p-0">
											<div
												class="subcategory-text-wrapper p-0"
												[ngClass]="
													legacyBrief ||
													(!category['titleName'] &&
														!isMarketplaceTab &&
														category[
															'projectTitle'
														]) ||
													(!marketplaceCategory[
														'titleName'
													] &&
														isMarketplaceTab &&
														marketplaceCategory[
															'projectTitle'
														])
														? 'text-grey-disabled'
														: ''
												">
												<div class="pl-1 pr-1">
													<p class="mb-0">
														{{ slidez?.title }}
													</p>
												</div>
												<div *ngIf="slidez?.isTemplate" class="my-2 text-color-gray">
													<span>
														<em
															class="fa fa-info-circle clickable ml-1 f-18"
															[popover]="templateTooltip"
															triggers="mouseenter:mouseleave click"
															placement="top"
															(click)="$event.stopPropagation()"></em>
														<ng-template #templateTooltip>
															{{ slidez?.project_sub_category?.title }}
														</ng-template>
													</span>
												</div>
											</div>
											<div
												class="disabled-text-wrapper project-category-text m font-11 ml-1 mr-1 mb-1 pt-3 pb-3"
												[ngClass]="{
													'template-category-background':
														slidez?.isTemplate,
													'regular-category-background':
														!slidez?.isTemplate
												}">
												{{
													slidez?.type?.title ||
														slidez
															?.project_sub_category
															?.type?.title
												}}
											</div>
										</div>
										<div
											*ngIf="slidez?.isTemplate"
											class="position-absolute template-rectangle">
											Template
										</div>
									</div>
								</slide>
							</ng-container>
							<div
								class="overlay position-absolute"
								*ngIf="
									(legacyBrief ||
										(!category['titleName'] &&
											!isMarketplaceTab &&
											category['projectTitle']) ||
										(!marketplaceCategory['titleName'] &&
											isMarketplaceTab &&
											marketplaceCategory[
												'projectTitle'
											])) &&
									!isMarketplaceTab
								">
								<div
									class="text-purple-color overlay-text-block">
									You’re using a legacy project type. Please
									start a new project using our current
									categories to change the type of project
									when copying it.
								</div>
							</div>
						</carousel>
					</div>
					<div 
						*ngIf="category['titleName'] && !isMarketplaceTab"
						class="mt-4"
					>
						<button (click)="clearAllFields()" class="btn btn-purple px-4">
							Clear All Fields
						</button>
					</div>
					<span
						class="d-flex mt-4"
						*ngIf="
							((!category['titleName'] &&
								!isMarketplaceTab &&
								category['projectTitle']) ||
								(!marketplaceCategory['titleName'] &&
									isMarketplaceTab &&
									marketplaceCategory['projectTitle'])) &&
							!isMarketplaceTab
						">
						<span>Project Type:&nbsp;</span>
						{{ oProject?.project_title_name }}
					</span>
					<span
						class="d-flex mt-4 text-color-gray"
						*ngIf="category['titleName'] && !isMarketplaceTab">
						<span>Project Type:&nbsp;</span>
						{{ category['titleName'] }}
					</span>
					<span
						class="d-flex mt-4"
						*ngIf="
							marketplaceCategory['titleName'] && isMarketplaceTab
						">
						<span>Project Type:&nbsp;</span>
						{{ marketplaceCategory['titleName'] }}
					</span>
					<div
						class="py-4 text-center"
						*ngIf="
							!oDefs?.titles?.length &&
							isCarouselSkeletonLoading &&
							!oSelectedTitle
						">
						<p class="mb-0">No results found</p>
					</div>
					<ng-container *ngIf="!isCarouselSkeletonLoading">
						<app-project-loader
							[isSkeletonShown]="true"></app-project-loader>
					</ng-container>
				</div>
			</div>
			<ng-container
				*ngIf="
					(!isMarketplaceTab && category['title']) ||
					(isMarketplaceTab && marketplaceCategory['title'])
				">
				<ng-container
					*ngIf="
						marketplaceCategory['type'] &&
						isMarketplaceTab &&
						marketplaceCategory['title']
					">
					<div class="my-3">
						<span
							*ngIf="
								hasMarketplaceAccess &&
								marketplacePackages?.length > 0 &&
								marketplaceCategory['type']
							">
							What best describes your project?
							<em
								class="fa fa-info-circle clickable ml-1 f-18"
								[popover]="marketplaceCategoryTooltip"
								triggers="mouseenter:mouseleave click"
								placement="right"
								(click)="$event.stopPropagation()"></em>
							<ng-template #marketplaceCategoryTooltip>
								What best describes your project.
							</ng-template>
						</span>
					</div>
					<div class="sub-category-info-wrapper mb-4">
						<div class="row">
							<ng-container
								*ngIf="
									hasMarketplaceAccess &&
									marketplacePackages?.length > 0 &&
									marketplaceCategory['type']
								">
								<ng-container
									*ngFor="let package of marketplacePackages">
									<div
										class="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-3 px-3 py-2 package-lists-wrapper">
										<div
											class="card package-list-card-wrapper">
											<div>
												<div class="card-body">
													<div
														class="package-list-image-wrapper"
														[ngStyle]="{
															'background-image':
																'url(' +
																package
																	?.document_file
																	?.full_path +
																')'
														}">
														<img
															class="package-image-content w-100"
															src="../../../{{
																packageBGImage
															}}"
															alt="" />
														<div
															class="discount-content-container">
															<img
																src="../../../../{{
																	discountCouponBgImage
																}}"
																alt="" />
															<div
																class="discount-content-text-wrapper font-11 px-2">
																<span>
																	{{
																		package?.discount
																			| number
																				: '1.0'
																	}}% DISCOUNT
																</span>
															</div>
														</div>
													</div>
													<div
														class="profile-picture">
														<img
															class="profile-picture-wrapper ml-3 clickable"
															(click)="
																initDesignerProjectModal(
																	package?.created_by_user,
																	designerProfileModal,
																	109,
																	'designer_package'
																)
															"
															[src]="
																package
																	?.created_by_user
																	?.profile_picture
																	?.full_path
															"
															onError="this.src='../../../../../assets/img/user-placeholder.png';"
															alt="" />
														<div
															(click)="
																initDesignerProjectModal(
																	package?.created_by_user,
																	designerProfileModal,
																	109,
																	'designer_package'
																)
															"
															class="profile-title text-underline ml-2 clickable">
															{{
																package
																	?.created_by_user
																	?.name
															}}
														</div>
													</div>
													<div
														class="mx-sm-2 mx-lg-2">
														<div
															class="clickable category-info"
															(click)="
																fetchPackageInfo(
																	package
																)
															">
															<div
																class="package-list-title-wrapper f-18 mb-3"
																[popover]="
																	packageTitle
																"
																[outsideClick]="
																	true
																"
																placement="right"
																triggers="mouseenter:mouseleave mouseenter:click">
																{{
																	package?.title
																}}
															</div>
															<ng-template
																#packageTitle>
																<div
																	[innerHtml]="
																		package?.title
																	"></div>
															</ng-template>
															<div
																class="package-list-content-wrapper disabled-text-wrapper"
																[popover]="
																	package?.project_type_name +
																	'-' +
																	package?.project_title_name
																"
																placement="auto"
																triggers="mouseenter:mouseleave click">
																{{
																	package?.project_type_name +
																		'-' +
																		package?.project_title_name
																}}
															</div>
															<hr />
															<div
																class="package-list-price-wrapper">
																<div>
																	<div
																		class="d-flex align-items-baseline flex-wrap category-price-wrapper">
																		<p
																			*ngIf="
																				package?.discounted_total !=
																					null &&
																				package?.discounted_total !==
																					0
																			"
																			class="discounted-price-wrapper text-primary ml-0 f-18 mb-0">
																			${{
																				+package[
																					'discounted_total'
																				] ||
																					0
																					| number
																						: '1.0-2'
																			}}
																		</p>
																		<p
																			[ngClass]="
																				package?.discounted_total !=
																					null &&
																				package?.discounted_total !==
																					0
																					? 'package-original-price-wrapper'
																					: ''
																			"
																			class="ml-3 f-18 mb-0">
																			${{
																				+package[
																					'total'
																				] ||
																					0
																					| number
																						: '1.0-2'
																			}}
																		</p>
																	</div>
																</div>
															</div>
															<div
																class="package-list-details-wrapper mt-3 mb-2"
																[innerHTML]="
																	package?.details
																"
																[popover]="
																	packageDetail
																"
																placement="auto"
																[outsideClick]="
																	true
																"
																triggers="mouseenter:mouseleave mouseenter:click"></div>
														</div>

														<ng-template
															#packageDetail>
															<div
																[innerHtml]="
																	package?.details
																"></div>
														</ng-template>

														<div
															class="d-flex select-btn--wrapper">
															<div>
																<button
																	*ngIf="
																		selectedPackage?.id !==
																		package?.id
																	"
																	(click)="
																		onSelectPackage(
																			package
																		)
																	"
																	class="btn select-btn-wrapper mt-2 mb-2"
																	type="submit">
																	Select
																</button>
																<button
																	*ngIf="
																		selectedPackage?.id ===
																		package?.id
																	"
																	class="btn selected-btn-wrapper mt-2 mb-2"
																	type="submit">
																	Selected
																</button>
															</div>
															<div
																class="d-flex justify-content-center align-items-center my-2 clickable w-100">
																<span
																	[popover]="
																		satisfactionGuaranteedTooltip
																	"
																	triggers="mouseenter:mouseleave click"
																	placement="top"
																	(click)="
																		$event.preventDefault();
																		$event.stopPropagation()
																	">
																	<p
																		class="satisfaction-content-wrapper mb-0">
																		Satisfaction
																	</p>
																	<p
																		class="satisfaction-content-wrapper mb-0">
																		Guaranteed!
																	</p>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<span class="ml-3 f-18" *ngIf="isPackageNotFound">
								No package found
							</span>
						</div>
						<div
							class="text-center py-1"
							*ngIf="
								hasMarketplaceAccess &&
								lastLoadedPackages?.data?.length ==
									createProjectPackageListLimit &&
								marketplaceCategory['type'] &&
								lastLoadedPackages?.next_page_url
							">
							<a
								href="javascript:void(0);"
								(click)="onClickLoadMorePackage()"
								class="display-4 text-dark">
								<span class="text-underline">Load More</span>
								<em
									class="ml-2 text-muted fas fa-chevron-down"></em>
							</a>
						</div>
					</div>
					<div
						class="mt-2 clickable"
						*ngIf="
							selectedPackage?.id ||
							oProjectsData?.project_package
						">
						<div class="d-flex flex-wrap">
							<span class="mr-3 mb-1">
								{{
									selectedPackage?.title
										? selectedPackage?.title
										: oProjectsData?.project_package?.title
								}}
							</span>
							<span class="disabled-text-wrapper mb-1">
								{{
									selectedPackage?.project_type_name
										? selectedPackage?.project_type_name
										: oProjectsData?.project_package
												?.project_type_name
								}}
								-
								{{
									selectedPackage?.project_title_name
										? selectedPackage?.project_title_name
										: oProjectsData?.project_package
												?.project_title_name
								}}
							</span>
						</div>
						<div
							class="my-2"
							[innerHTML]="
								selectedPackage?.details
									? selectedPackage?.details
									: oProjectsData?.project_package?.details
							"></div>
						<div class="my-4 f-18">
							Add your details for this project:
						</div>
					</div>
				</ng-container>
				<div>
					<div>
						<h5 class="text-color-gray">
							Project Title
							<span class="text-danger">*</span>
							<em
								placement="right"
								[popover]="projectTitleTooltip"
								triggers="mouseenter:mouseleave click"
								class="fa fa-info-circle clickable ml-1 f-18"></em>
							<ng-template #projectTitleTooltip>
								Give a title that is short, accurate and will
								help you identify the project
							</ng-template>
						</h5>
					</div>
					<div class="form-group">
						<input
							*ngIf="isMarketplaceTab"
							[ngClass]="{
								'border-red':
									isMarketplaceValidationShown &&
									!marketplaceCategory.projectTitle.trim()
										.length
							}"
							type="text"
							class="form-control"
							[(ngModel)]="marketplaceCategory.projectTitle"
							placeholder="Use a title that will help you identify the project" />
						<input
							*ngIf="!isMarketplaceTab"
							[ngClass]="{
								'border-red':
									isValidationShown &&
									!category.projectTitle.trim().length
							}"
							type="text"
							class="form-control"
							[(ngModel)]="category.projectTitle"
							placeholder="Use a title that will help you identify the project" />
						<div
							class="text-danger mt-2"
							*ngIf="
								(isValidationShown &&
									!category.projectTitle.trim().length &&
									!isMarketplaceTab) ||
								(isMarketplaceValidationShown &&
									!marketplaceCategory.projectTitle.trim()
										.length &&
									isMarketplaceTab)
							">
							This field is required.
						</div>
					</div>
				</div>
				<app-ai-assist
					[directionList]="
						isMarketplaceTab
							? marketplaceCategory['directionList']
							: category.directionList || []
					"
					[isValidationShown]="isValidationShown"
					[isSaveProject]="isSaveProject"
					[isMarketplaceValidationShown]="
						isMarketplaceValidationShown
					"
					[selectedPackage]="
						isMarketplaceTab ? marketplaceCategory : category
					"
					[isMarketplaceTab]="isMarketplaceTab"
					[uploadedFile]="
						isMarketplaceTab
							? marketplaceCategory['uploadedFile']
							: category['uploadedFile']
					"
					[aiConfig]="this.oDefs['aiConfig']"
					[aiImageList]="aiImageList"
					[aiDetails]="aiDetails"
					(aiDetail)="aiDetails = $event"
					(UpdatedFileList)="updatedAiFileList($event)"
					(aiImgDetails)="aiImageList = $event"
					(updatedDirectionList)="
						updatedDirectionList($event)
					"></app-ai-assist>
				<ng-container>
					<app-bucket-detail
						(selectedBucketDetail)="selectedBucketDetail($event)"
						[selectedBucket]="category?.selectedBucket"
						[bCopyBrief]="bCopyBrief"
						[oProject]="oProject"></app-bucket-detail>
				</ng-container>
				<div
					class="mb-2"
					#scrollToProgressBar
					*ngIf="
						bShowProgressBar &&
						((category['uploadedFile'].length &&
							!isMarketplaceTab) ||
							(marketplaceCategory['uploadedFile'].length &&
								isMarketplaceTab))
					">
					<progressbar
						[animate]="false"
						[value]="progressBarPercentage"
						type="success">
						<b>{{ progressBarPercentage }}%</b>
					</progressbar>
				</div>
				<app-file-uploader
					[bShowProgressBar]="bShowProgressBar"
					[uploadedFile]="
						isMarketplaceTab
							? marketplaceCategory['uploadedFile']
							: category['uploadedFile']
					"
					[aiImageList]="aiImageList"
					[copyBriefFile]="
						isMarketplaceTab
							? marketplaceCategory['copyBriefFile']
							: category['copyBriefFile']
					"
					(fileDetail)="fileDetail($event)"
					(aiImgDetails)="aiImageList = $event"
					(copyBriefFileDetail)="
						copyBriefFileDetail($event)
					"></app-file-uploader>

				<div id="collapse02">
					<div *ngIf="aTeamMembers?.length">
						<div class="row">
							<div class="col-12">
								<div class="p mb-3 d-flex">
									<p class="mb-0 text-color-gray">
										Invite a
										<span *ngIf="!isWhiteLabelledUser">
											Flocksy
										</span>
										team member to work on this project
									</p>
									<em
										[popover]="teamMemberTooltip"
										placement="right"
										triggers="mouseenter:mouseleave click"
										class="fa fa-info-circle clickable ml-1 f-18"></em>
									<ng-template #teamMemberTooltip>
										When you invite a team member, they'll
										be notified and assigned to your project
										if they're available. You can also
										exclude a team member from this
										particular project.
									</ng-template>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="sub-category-info-wrapper">
									<carousel
										[interval]="'false'"
										[itemsPerSlide]="itemsPerSlide"
										[showIndicators]="false"
										[noWrap]="true">
										<slide
											class="m-0"
											*ngFor="
												let oTeamMember of aTeamMembers;
												let i = index
											">
											<div
												class="slide card mx-3 team-member-slider mb-0 clickable"
												[ngClass]="{
													'border-green':
														aPayloadInviteDesigner.indexOf(
															oTeamMember?.id
														) > -1,
													'border-red':
														aExcludedTeamMembers.indexOf(
															oTeamMember?.id
														) > -1
												}">
												<div
													class="card-body text-center d-flex flex-column align-items-center justify-content-between">
													<img
														class="mb-1 img-fluid rounded-circle thumb66"
														src="{{
															oTeamMember?.profile_pic
														}}"
														alt=""
														onError="this.src='../../../assets/img/user-placeholder.png';" />
													<div class="w-100">
														<p class="mb-1">
															{{
																oTeamMember?.name
															}}
														</p>
														<hr class="my-2" />
													</div>

													<div
														class="d-flex justify-content-center inviteTeammember">
														<div
															class="mr-2 clickable"
															[ngClass]="
																aExcludedTeamMembers?.indexOf(
																	oTeamMember?.id
																) > -1
																	? 'disabled'
																	: ''
															"
															*ngIf="
																aPayloadInviteDesigner.indexOf(
																	oTeamMember?.id
																) == -1
															"
															(click)="
																onClickAddTeamMember(
																	'step1',
																	oTeamMember?.id,
																	oTeamMember?.creative_level_id
																)
															"
															[popover]="
																inviteTeamTooltip
															"
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	includeIcon
																"
																alt="" />
														</div>
														<ng-template
															#inviteTeamTooltip>
															<div
																class="tooltip-text">
																Invite
																{{
																	oTeamMember?.name
																}}
																to increase the
																likelihood they
																will work on
																your project.
															</div>
														</ng-template>
														<div
															class="mr-2 clickable"
															*ngIf="
																aPayloadInviteDesigner.indexOf(
																	oTeamMember?.id
																) > -1
															"
															(click)="
																onClickRemoveTeamMember(
																	'step1',
																	oTeamMember.id
																)
															"
															popover="Invite {{
																oTeamMember?.name
															}} to increase the likelihood they will work on your project."
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	selectedIncludeIcon
																"
																alt="" />
														</div>
														<div
															class="mr-2 clickable"
															*ngIf="
																aExcludedTeamMembers?.indexOf(
																	oTeamMember?.id
																) == -1
															"
															(click)="
																onClickAddExcludeTeamMember(
																	oTeamMember?.id
																)
															"
															popover="Exclude {{
																oTeamMember?.name
															}} from this project. This won’t remove them from your team."
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	excludeIcon
																"
																alt="" />
														</div>
														<div
															class="mr-2 clickable"
															(click)="
																onClickRemoveExcludeTeamMember(
																	oTeamMember?.id
																)
															"
															*ngIf="
																aExcludedTeamMembers.indexOf(
																	oTeamMember?.id
																) > -1
															"
															popover="Exclude {{
																oTeamMember?.name
															}} from this project. This won’t remove them from your team."
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	selectedExcludeIcon
																"
																alt="" />
														</div>
														<div
															class="mr-2 clickable"
															(click)="
																initStats(
																	oTeamMember,
																	initStatsModal
																)
															"
															popover="View ratings from previous work you have done with {{
																oTeamMember?.name
															}}."
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	statsIcon
																"
																alt="" />
														</div>
														<div
															class="mr-2 clickable"
															(click)="
																initNotes(
																	oTeamMember,
																	initNotesModal
																)
															"
															popover="Leave a note about {{
																oTeamMember?.name
															}}. Notes here can only be viewed by this account."
															placement="top"
															triggers="mouseenter:mouseleave click">
															<img
																[src]="
																	'../../../../../' +
																	notesIcon
																"
																alt="" />
														</div>
													</div>
												</div>
											</div>
										</slide>
									</carousel>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="aSuggestedTeamMembers.length > 0">
						<hr class="mb-4" />
						<h4 class="mb-4">Suggested Team Members</h4>
						<div class="row">
							<div
								class="col-md-4 col-lg-3"
								*ngFor="
									let oTeamMember of aSuggestedTeamMembers;
									let j = index
								">
								<div class="card border">
									<div class="card-body text-center">
										<img
											class="mb-2 img-fluid rounded-circle thumb96 img-thumbnail"
											src="{{ oTeamMember?.profile_url }}"
											alt=""
											onError="this.src='../../../assets/img/user-placeholder.png';" />
										<div>
											<h4>
												{{ oTeamMember?.name }}
											</h4>
											<ng-container
												*ngTemplateOutlet="
													showInviteExclude;
													context: {
														oTeamMember:
															oTeamMember,
														showExlude: false
													}
												"></ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div
								class="col-md-12 text-center text-underline"
								*ngIf="
									oSuggestedTmPagination['totalRecords'] >
									aSuggestedTeamMembers?.length
								">
								<a
									href="javascript:"
									(click)="onClickLoadMoreSuggestedTm()">
									See more team members
								</a>
							</div>
						</div>
					</div>
				</div>
				<ng-container
					*ngIf="
						!isSubClientUser &&
						!isMarketplaceTab &&
						category['title']
					">
					<app-sub-user-info
						[itemsPerSlide]="itemsPerSlide"
						[assignedUsers]="category['assignedUser']"
						(subUserDetail)="
							subUserDetail($event)
						"></app-sub-user-info>
				</ng-container>

				<ng-container
					*ngIf="
						category?.title &&
						category?.type &&
						oDefs['questions'] &&
						!isMarketplaceTab
					">
					<ng-container
						*ngIf="
							oDefs['questions'][category?.title]?.length &&
							oDefs['titles']?.length
						">
						<form
							role="form"
							class="questionContainer"
							*ngIf="
								category['questionForms'] &&
								category['questionForms']['steps'] &&
								category['title']
							"
							[formGroup]="category['questionForms']['steps']">
							<div
								#scrollToInvalidQueField
								*ngFor="
									let question of oDefs['questions'][
										category['title']
									];
									let i = index
								">
								<div
									[ngClass]="{
										'questions-margin':
											!question?.custom ||
											question?.custom_field == 'title'
									}"
									id="step-{{ i + 1 }}"
									[formGroupName]="'step' + (i + 1)">
									<div class="col-xs-12">
										<div class="row">
											<div
												class="col-md-12"
												[ngClass]="{
													'madatory-field':
														oSelectedType &&
														oSelectedTitle &&
														category[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														]
															.get('title')
															.hasError(
																'required'
															) &&
														!category[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														].get('title').valid
												}">
												<div>
													<span
														class="circle circle-md mr-2 mt-2 float-left"
														[ngClass]="{
															'bg-primary':
																question?.status ==
																'1',
															'bg-danger':
																question?.status ==
																'0'
														}"
														*ngIf="
															showWhichStatus &&
															(!question?.custom ||
																(question?.custom &&
																	question?.custom_field ==
																		'title'))
														"></span>
													<p
														class="text-color-gray"
														*ngIf="
															!question?.custom
														">
														{{ question?.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question?.question
															"
															class="fa fa-info-circle clickable ml-1 f-18"
															[popover]="
																questionTooltip
															"
															placement="right"
															triggers="mouseenter:mouseleave click"></em>
													</p>
													<p
														class="text-color-gray"
														*ngIf="
															question?.custom &&
															question?.custom_field ==
																'title'
														">
														{{ question?.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question?.question
															"
															class="fa fa-info-circle clickable ml-1 f-18"
															[popover]="
																questionTooltip
															"
															placement="right"
															triggers="mouseenter:mouseleave click"></em>
													</p>
													<ng-template
														#questionTooltip>
														<div
															style="
																max-width: 300px;
															">
															{{
																question?.question
															}}
														</div>
													</ng-template>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
															oQuestionTypes?.radio ||
														question?.project_question_type_id ==
															oQuestionTypes?.enum
													"
													class="mb-2">
													<div
														class="c-radio mb-1"
														*ngFor="
															let radio_option of oDefs[
																'app_list_strings'
															][
																question[
																	'field_options'
																]
															] | objectToIterable
														">
														<label
															class="disabled-text-wrapper">
															<input
																type="radio"
																formControlName="title"
																value="{{
																	radio_option?.key
																}}" />
															<span
																[ngClass]="{
																	'error-message':
																		category[
																			'questionForms'
																		][
																			'steps'
																		]
																			.controls[
																			'step' +
																				(i +
																					1)
																		]
																			.invalid &&
																		isValidationShown
																}"
																class="fa fa-circle text-color-gray"></span>
															{{
																radio_option?.value
															}}
														</label>
													</div>
													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].invalid &&
															isValidationShown
														">
														This field is required.
													</div>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.bucket
													"
													class="mt-2 clickable">
													<ng-container>
														<app-bucket-detail
															(selectedBucketDetail)="
																selectedBucketDetail(
																	$event
																)
															"
															[bCopyBrief]="
																bCopyBrief
															"
															[oProject]="
																oProject
															"></app-bucket-detail>
													</ng-container>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
															oQuestionTypes?.checkbox ||
														question?.project_question_type_id ==
															oQuestionTypes?.multienum
													"
													class="">
													<div
														class="checkbox c-checkbox mb-1"
														formArrayName="title"
														*ngFor="
															let item of category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.controls;
															let j = index
														">
														<div
															[formGroupName]="j">
															<label
																class="disabled-text-wrapper">
																<input
																	type="checkbox"
																	formControlName="value" />
																<span
																	[ngClass]="{
																		'border-red':
																			category[
																				'questionForms'
																			][
																				'steps'
																			]
																				.controls[
																				'step' +
																					(i +
																						1)
																			]
																				.invalid &&
																			isValidationShown
																	}"
																	class="fa fa-check"></span>
																{{
																	category[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	]
																		.get(
																			'title'
																		)
																		.controls[
																			j
																		].get(
																			'text'
																		).value
																}}
															</label>
														</div>
													</div>

													<ng-container
														*ngIf="
															question.title ==
															'Sizes'
														">
														<form #form="ngForm">
															<div
																ngModelGroup="sizesGroup">
																<div
																	*ngFor="
																		let size of customSizesArray;
																		let i = index
																	"
																	class="d-flex flex-wrap flex-row custom-size-wrapper">
																	<div
																		class="disabled-text-wrapper">
																		<div
																			class="checkbox c-checkbox mb-1">
																			<div>
																				<label
																					class="disabled-text-wrapper">
																					<input
																						type="checkbox"
																						[checked]="
																							size.selected
																						"
																						name="selected{{
																							i
																						}}"
																						(change)="
																							onCheckboxChange(
																								size,
																								$event
																							)
																						" />
																					<span
																						class="fa fa-check"></span>
																					{{
																						size.customWidth
																					}}
																					{{
																						size.customType
																					}}
																					x
																					{{
																						size.customHeight
																					}}
																					{{
																						size.customType
																					}}
																				</label>
																			</div>
																		</div>
																	</div>
																	<div class="ml-2 clickable"
																		 [popover]="'Delete'"
																		 triggers="mouseenter:mouseleave click"
																		 placement="top"
																		 (click)="deleteCustomSize(size)">
																		<img src="assets/img/trash-icon-grey-small.svg" alt="">
																	</div>
																</div>
															</div>

															<div
																class="checkbox c-checkbox mb-1">
																<div>
																	<label
																		class="disabled-text-wrapper">
																		Custom
																		size
																	</label>
																</div>
															</div>

															<div
																class="d-flex flex-column custom-size-wrapper">
																<div
																	class="d-flex flex-wrap w-100 mb-3">
																	<div
																		class="mr-3 custom-size-input mb-3">
																		<input
																			name="customWidth"
																			[(ngModel)]="
																				newCustomSize.customWidth
																			"
																			type="number"
																			class="form-control"
																			min="0"
																			[ngClass]="{
																				'border-red':
																					customSizeMessage &&
																					!newCustomSize.customWidth
																			}"
																			[disabled]="
																				customSizesArray.length ===
																					5 &&
																				editingIndex ===
																					-1
																			"
																			placeholder="Width" />
																		<p
																			class="mt-1 text-danger"
																			*ngIf="
																				customSizeMessage &&
																				!newCustomSize.customWidth
																			">
																			This
																			field
																			is
																			required
																		</p>
																	</div>
																	<div
																		class="mr-3 custom-size-input mb-3">
																		<input
																			name="customHeight"
																			[(ngModel)]="
																				newCustomSize.customHeight
																			"
																			type="number"
																			class="form-control"
																			min="0"
																			[ngClass]="{
																				'border-red':
																					customSizeMessage &&
																					!newCustomSize.customHeight
																			}"
																			[disabled]="
																				customSizesArray.length ===
																					5 &&
																				editingIndex ===
																					-1
																			"
																			placeholder="Height" />
																		<p
																			class="mt-1 text-danger"
																			*ngIf="
																				customSizeMessage &&
																				!newCustomSize.customHeight
																			">
																			This
																			field
																			is
																			required
																		</p>
																	</div>
																	<div
																		class="custom-size-selector mb-3">
																		<ngx-select
																			name="customType"
																			[(ngModel)]="
																				newCustomSize.customType
																			"
																			[items]="
																				customTypeList
																			"
																			[ngModelOptions]="{
																				standalone: true
																			}"
																			[ngClass]="{
																				'required-field':
																					customSizeMessage &&
																					!newCustomSize.customType
																			}"
																			placeholder="Type"
																			[disabled]="
																				customSizesArray.length ===
																					5 &&
																				editingIndex ===
																					-1
																			"
																			(select)="
																				selectType(
																					$event
																				)
																			"
																			optionValueField="id"
																			optionTextField="name"
																			[multiple]="
																				false
																			"
																			[keepSelectedItems]="
																				false
																			"></ngx-select>
																		<p
																			class="mt-1 text-danger"
																			*ngIf="
																				customSizeMessage &&
																				!newCustomSize.customType
																			">
																			This
																			field
																			is
																			required
																		</p>
																	</div>
																	<div
																		class="custom-size-selector mb-3 ml-3"
																		*ngIf="
																			customSizesArray.length <
																			5
																		">
																		<img
																			src="assets/img/green-plus-icon.svg"
																			alt="plus-icon"
																			[ngClass]="
																				!newCustomSize.customWidth ||
																				!newCustomSize.customHeight ||
																				!newCustomSize.customType
																					? 'opacity-40'
																					: 'clickable'
																			"
																			[popover]="
																				'Add size'
																			"
																			triggers="mouseenter:mouseleave click"
																			placement="top"
																			(click)="
																				newCustomSize.customWidth &&
																				newCustomSize.customHeight &&
																				newCustomSize.customType
																					? addCustomSize()
																					: ''
																			" />
																	</div>
																	<div
																		class="custom-size-selector mb-3 ml-3"
																		*ngIf="
																			customSizesArray.length ===
																			5
																		">
																		<img
																			src="assets/img/green-plus-icon.svg"
																			alt="plus-icon"
																			class="clickable opacity-40"
																			[ngClass]="
																				customSizesArray.length ===
																				5
																					? 'opacity-40'
																					: ''
																			"
																			[popover]="
																				'Maximum custom sizes reached'
																			"
																			triggers="mouseenter:mouseleave click"
																			placement="top" />
																	</div>
																</div>
															</div>
														</form>
													</ng-container>

													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthCheckbox'
																) &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.invalid &&
															isValidationShown
														">
														This field is required.
													</div>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.file
													">
													<div
														class="mb-2"
														*ngIf="
															bShowProgressBar &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.controls.length
														">
														<progressbar
															[animate]="false"
															[value]="
																progressBarPercentageSection2
															"
															type="success">
															<b>
																{{
																	progressBarPercentageSection2
																}}%
															</b>
														</progressbar>
													</div>

													<div
														class="form-group mb-0">
														<div class="row m-0">
															<div
																class="col-md-12 px-0">
																<input
																	type="file"
																	#fileUploadInput
																	multiple="multiple"
																	class="file-selector"
																	(change)="
																		onSingleQueFileSelected(
																			$event,
																			'step' +
																				(i +
																					1)
																		)
																	" />
																<div
																	ng2FileDrop
																	[uploader]="
																		oUploader
																	"
																	[ngClass]="{
																		'nv-file-over':
																			oBaseDropZoneOver,
																		'border-red':
																			category[
																				'questionForms'
																			][
																				'steps'
																			]
																				.controls[
																				'step' +
																					(i +
																						1)
																			]
																				.invalid &&
																			isValidationShown
																	}"
																	class="bg-white border box-placeholder my-drop-zone text-center nv-file-over">
																	<img
																		[src]="
																			'../../../../../' +
																			uploadFileIcon
																		"
																		alt=""
																		class="mb-3" />
																	<p
																		class="disabled-text-wrapper mb-0">
																		Click or
																		drag
																		file to
																		this
																		area to
																		upload
																	</p>
																	<span
																		class="font-12 upload-file-text">
																		Support
																		for a
																		single
																		or bulk
																		upload.
																		Maximum
																		file
																		size 2
																		GB.
																	</span>
																</div>
															</div>
														</div>
														<div
															class="d-flex flex-wrap">
															<ng-container
																*ngIf="
																	category[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	].get(
																		'title'
																	).controls
																		.length >
																	0
																">
																<div
																	class="uploaded-image d-flex mb-3"
																	formArrayName="title"
																	*ngFor="
																		let item of category[
																			'questionForms'
																		][
																			'steps'
																		].controls[
																			'step' +
																				(i +
																					1)
																		].get(
																			'title'
																		)
																			.controls;
																		let j = index
																	">
																	<div
																		[formGroupName]="
																			j
																		">
																		<ng-container
																			*ngIf="
																				imageMimeTypes?.indexOf(
																					category[
																						'questionForms'
																					][
																						'steps'
																					].controls[
																						'step' +
																							(i +
																								1)
																					].get(
																						'title'
																					)
																						.controls[
																						j
																					]
																						?.value
																						?.file
																						?.type
																				) >
																				-1
																			">
																			<img
																				class="uploaded-img-wrapper"
																				[popover]="
																					nameTooltip
																				"
																				triggers="mouseenter:mouseleave click"
																				placement="top"
																				[src]="
																					category[
																						'questionForms'
																					][
																						'steps'
																					].controls[
																						'step' +
																							(i +
																								1)
																					].get(
																						'title'
																					)
																						.controls[
																						j
																					]
																						?.value
																						?.imgUrl
																				"
																				[alt]="
																					category[
																						'questionForms'
																					][
																						'steps'
																					].controls[
																						'step' +
																							(i +
																								1)
																					].get(
																						'title'
																					)
																						.controls[
																						j
																					]
																						?.value
																						?.file
																						?.name
																				"
																				width="109px"
																				height="109px" />
																			<img
																				(click)="
																					unsetQueFile(
																						'step' +
																							(i +
																								1),
																						j
																					)
																				"
																				class="img-delete-icon clickable"
																				[src]="
																					'../../../../../' +
																					deleteIcon
																				"
																				alt="" />
																		</ng-container>
																		<ng-container
																			*ngIf="
																				imageMimeTypes?.indexOf(
																					category[
																						'questionForms'
																					][
																						'steps'
																					].controls[
																						'step' +
																							(i +
																								1)
																					].get(
																						'title'
																					)
																						.controls[
																						j
																					]
																						?.value
																						?.file
																						?.type
																				) ==
																				-1
																			">
																			<img
																			class="uploaded-img-wrapper"
																			[popover]="
																			nameTooltip
																			"
																			triggers="mouseenter:mouseleave
																			click"
																			placement="top"
																			[src]="
																			'../../../../../'
																			+
																			defaultFileImg
																			"
																			item[
																			j ]
																			?.value
																			?.file
																			?.name
																			"
																			width="109px"
																			height="109px"
																			/>
																			<img
																				(click)="
																					unsetQueFile(
																						'step' +
																							(i +
																								1),
																						j
																					)
																				"
																				class="img-delete-icon clickable"
																				[src]="
																					'../../../../../' +
																					deleteIcon
																				"
																				alt="" />
																		</ng-container>
																		<ng-template
																			#nameTooltip>
																			{{
																				category[
																					'questionForms'
																				][
																					'steps'
																				].controls[
																					'step' +
																						(i +
																							1)
																				].get(
																					'title'
																				)
																					.controls[
																					j
																				]
																					?.value
																					?.name
																			}}
																		</ng-template>
																	</div>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	] &&
																	oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	].length > 0
																">
																<div
																	class="uploaded-image d-flex mb-3"
																	*ngFor="
																		let oCopyDoc of oCopyBriefFiles[
																			'step' +
																				(i +
																					1)
																		];
																		let j = index
																	">
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				oCopyDoc?.file_mime_type
																			) >
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[popover]="
																				nameTooltip
																			"
																			triggers="mouseenter:mouseleave click"
																			placement="top"
																			[src]="
																				oCopyDoc?.full_path
																			"
																			[alt]="
																				oCopyDoc?.filename
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetCopyDoc(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																	</ng-container>
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				oCopyDoc?.file_mime_type
																			) ==
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[popover]="
																				nameTooltip
																			"
																			triggers="mouseenter:mouseleave click"
																			placement="top"
																			[src]="
																				'../../../../../' +
																				defaultFileImg
																			"
																			[alt]="
																				oCopyDoc?.filename
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetCopyDoc(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																		<ng-template
																			#nameTooltip>
																			{{
																				oCopyDoc?.filename
																			}}
																		</ng-template>
																	</ng-container>
																</div>
															</ng-container>
															<div
																class="uploaded-image-spinner uploading-spinner-wrapper"
																*ngIf="
																	fileLoaderShown
																">
																<div
																	class="spinner-border"
																	role="status">
																	<span
																		class="visually-hidden"></span>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.text
													">
													<div class="form-group">
														<input
															type="text"
															[ngClass]="{
																'border-red':
																	category[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	]
																		.get(
																			'title'
																		)
																		.hasError(
																			'required'
																		) &&
																	category[
																		'questionForms'
																	]['steps']
																		.controls[
																		'step' +
																			(i +
																				1)
																	].invalid &&
																	isValidationShown
															}"
															class="form-control title"
															placeholder="{{
																question?.question_placeholder
															}}"
															formControlName="title" />
													</div>
													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].invalid &&
															isValidationShown
														">
														This field is required.
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.textarea
													">
													<div class="row m-0">
														<div
															class="col-md-12 project-brief-inline-editor form-group p-0"
															[ngClass]="{
																'border-red':
																	category[
																		'questionForms'
																	]['steps']
																		.controls[
																		'step' +
																			(i +
																				1)
																	].invalid &&
																	isValidationShown
															}">
															<div
																formControlName="title"
																[ngxSummernote]="
																	getConfig(
																		question
																	)
																"></div>
														</div>
													</div>
													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].invalid &&
															isValidationShown
														">
														This field is required.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="isMarketplaceTab">
					<form
						role="form"
						class="questionContainer"
						*ngIf="
							marketplaceCategory['questionForms']['steps'] &&
							(selectedPackage?.id ||
								oProject?.concept_id ==
									projectConcepts?.marketplace)
						"
						[formGroup]="
							marketplaceCategory['questionForms']['steps']
						"
						(ngSubmit)="submitMarketplaceProject()"
						id="questionContainerGroup">
						<div>
							<div
								*ngFor="
									let question of marketplaceQuestions;
									let i = index
								">
								<div
									[ngClass]="{
										'questions-margin':
											!question?.custom ||
											question?.custom_field == 'title',
										'mt-4': i > 0
									}"
									id="step-{{ i + 1 }}"
									[formGroupName]="'step' + (i + 1)">
									<div class="col-xs-12">
										<div
											class="row"
											[ngClass]="{
												'ml-0':
													question?.project_question_type_id ==
													oQuestionTypes?.textarea
											}">
											<div
												class="col-md-12"
												[ngClass]="{
													'madatory-field':
														oSelectedType &&
														oSelectedTitle &&
														marketplaceCategory[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														]
															.get('title')
															.hasError(
																'required'
															) &&
														!marketplaceCategory[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														].get('title').valid,

													'p-0':
														question?.project_question_type_id ==
														oQuestionTypes?.textarea
												}">
												<div>
													<span
														class="circle circle-md mr-2 mt-2 float-left"
														[ngClass]="{
															'bg-primary':
																question?.status ==
																'1',
															'bg-danger':
																question?.status ==
																'0'
														}"
														*ngIf="
															showWhichStatus &&
															(!question?.custom ||
																(question?.custom &&
																	question?.custom_field ==
																		'title'))
														"></span>
													<p
														*ngIf="
															!question?.custom
														">
														{{ question?.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question?.question
															"
															class="fa fa-info-circle clickable ml-1 f-18"
															[popover]="
																questionTooltip
															"
															placement="right"
															triggers="mouseenter:mouseleave click"></em>
													</p>
													<p
														*ngIf="
															question?.custom &&
															question?.custom_field ==
																'title'
														">
														{{ question?.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question?.question
															"
															class="fa fa-info-circle clickable ml-1 f-18"
															[popover]="
																questionTooltip
															"
															placement="right"
															triggers="mouseenter:mouseleave click"></em>
													</p>
													<ng-template
														#questionTooltip>
														<div
															style="
																max-width: 300px;
															">
															{{
																question?.question
															}}
														</div>
													</ng-template>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.enum
													"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}"
													class="ans-list">
													<div
														class="ans-item form-group">
														<select
															class="form-control"
															formControlName="title">
															<option value="">
																Select
															</option>
															<option
																*ngFor="
																	let select_option of oDefs[
																		'app_list_strings'
																	][
																		question[
																			'field_options'
																		]
																	]
																		| objectToIterable
																"
																[ngValue]="
																	select_option.key
																">
																{{
																	select_option?.value
																}}
															</option>
														</select>
													</div>
												</div>

												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.multienum
													"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<div
														class="form-group form-group"
														*ngIf="
															!question?.custom
														">
														<ngx-select
															[items]="
																aAppListString[
																	question
																		.field_options
																]
															"
															optionValueField="id"
															optionTextField="name"
															placeholder="{{
																question.question_placeholder
															}}"
															formControlName="title"
															[multiple]="
																true
															"></ngx-select>
													</div>
													<div
														class="text-danger"
														*ngIf="
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthArray'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.radio
													"
													class="mb-2"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<div
														class="c-radio mb-1"
														*ngFor="
															let radio_option of oDefs[
																'app_list_strings'
															][
																question[
																	'field_options'
																]
															] | objectToIterable
														">
														<label
															class="disabled-text-wrapper">
															<input
																type="radio"
																formControlName="title"
																value="{{
																	radio_option.key
																}}" />
															<span
																class="fa fa-circle"></span>
															{{
																radio_option?.value
															}}
														</label>
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.checkbox
													"
													class="mb-2">
													<div
														class="checkbox c-checkbox mb-1"
														formArrayName="title"
														*ngFor="
															let item of marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.controls;
															let j = index
														">
														<div>
															<label
																class="disabled-text-wrapper">
																<input
																	type="checkbox"
																	formControlName="value" />
																<span
																	class="fa fa-check"></span>
																{{
																	marketplaceCategory[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	]
																		.get(
																			'title'
																		)
																		.controls[
																			j
																		].get(
																			'text'
																		).value
																}}
															</label>
														</div>
													</div>
													<div
														class="text-danger"
														*ngIf="
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthCheckbox'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.text
													">
													<div
														class="form-group"
														[ngClass]="{
															'has-error':
																marketplaceCategory[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<input
															type="text"
															class="form-control"
															placeholder="{{
																question?.question_placeholder
															}}"
															formControlName="title" />
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.textarea
													">
													<div class="row">
														<div
															class="col-md-12 project-brief-inline-editor form-group">
															<div
																formControlName="title"
																[ngxSummernote]="
																	getConfig(
																		question
																	)
																"></div>
														</div>
													</div>
												</div>
												<div
													*ngIf="
														question?.project_question_type_id ==
														oQuestionTypes?.file
													">
													<div
														class="mb-2"
														*ngIf="
															bShowProgressBar
														">
														<progressbar
															[animate]="false"
															[value]="
																progressBarPercentage
															"
															type="success">
															<b>
																{{
																	progressBarPercentage
																}}%
															</b>
														</progressbar>
													</div>
													<div
														class="form-group mb-3"
														[ngClass]="{
															'has-error':
																marketplaceCategory[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<div class="row m-0">
															<div
																class="col-md-12 px-0">
																<input
																	type="file"
																	multiple="multiple"
																	class="file-selector"
																	(change)="
																		onSingleQueFileSelected(
																			$event,
																			'step' +
																				(i +
																					1)
																		)
																	" />
																<div
																	ng2FileDrop
																	[ngClass]="{
																		'nv-file-over':
																			oBaseDropZoneOver
																	}"
																	[uploader]="
																		oUploader
																	"
																	class="bg-white border box-placeholder my-drop-zone text-center nv-file-over">
																	<img
																		[src]="
																			'../../../../../' +
																			uploadFileIcon
																		"
																		alt=""
																		class="mb-3" />
																	<p
																		class="disabled-text-wrapper mb-0">
																		Click or
																		drag
																		file to
																		this
																		area to
																		upload
																	</p>
																	<span
																		class="font-12 upload-file-text">
																		Support
																		for a
																		single
																		or bulk
																		upload.
																		Maximum
																		file
																		size
																		2MB.
																	</span>
																</div>
															</div>
														</div>
														<div
															class="d-flex flex-wrap">
															<ng-container
																*ngIf="
																	marketplaceCategory[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	].get(
																		'title'
																	).controls
																		.length >
																	0
																">
																<div
																	class="uploaded-image d-flex mb-3"
																	formArrayName="title"
																	*ngFor="
																		let item of marketplaceCategory[
																			'questionForms'
																		][
																			'steps'
																		].controls[
																			'step' +
																				(i +
																					1)
																		].get(
																			'title'
																		)
																			.controls;
																		let j = index
																	">
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				item
																					?.value
																					?.file
																					?.type
																			) >
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[src]="
																				item
																					?.value
																					?.imgUrl
																			"
																			[alt]="
																				item
																					?.value
																					?.file
																					?.name
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetQueFile(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																	</ng-container>
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				item
																					?.value
																					?.file
																					?.type
																			) ==
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[src]="
																				'../../../../../' +
																				defaultFileImg
																			"
																			[alt]="
																				item
																					?.value
																					?.file
																					?.name
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetCopyDoc(
																					i
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																	</ng-container>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	] &&
																	oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	].length > 0
																">
																<div
																	class="uploaded-image d-flex mb-3"
																	*ngFor="
																		let oCopyDoc of oCopyBriefFiles[
																			'step' +
																				(i +
																					1)
																		];
																		let j = index
																	">
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				oCopyDoc?.file_mime_type
																			) >
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[src]="
																				oCopyDoc?.full_path
																			"
																			[alt]="
																				oCopyDoc?.filename
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetQueFile(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																	</ng-container>
																	<ng-container
																		*ngIf="
																			imageMimeTypes?.indexOf(
																				oCopyDoc?.file_mime_type
																			) ==
																			-1
																		">
																		<img
																			class="uploaded-img-wrapper"
																			[src]="
																				'../../../../../' +
																				defaultFileImg
																			"
																			[alt]="
																				oCopyDoc?.filename
																			"
																			width="109px"
																			height="109px" />
																		<img
																			(click)="
																				unsetQueFile(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			"
																			class="img-delete-icon clickable"
																			[src]="
																				'../../../../../' +
																				deleteIcon
																			"
																			alt="" />
																	</ng-container>
																</div>
															</ng-container>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					<ng-container
						*ngIf="
							selectedPackage?.id ||
							oProject?.concept_id == projectConcepts?.marketplace
						">
						<div class="f-24 total-price-wrapper">
							Total:
							<span
								*ngIf="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
								"
								class="discounted-price-wrapper text-primary ml-0">
								${{
									selectedPackage['discounted_total'] || 0
										| number: '1.0-2'
								}}
							</span>
							<span
								class="ml-2"
								[ngClass]="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
										? 'package-original-price-wrapper'
										: ''
								">
								${{
									(selectedPackage
										? selectedPackage?.total
										: oProjectsData?.project_package?.total
									) | number: '1.0-0'
								}}
							</span>
						</div>
						<hr class="my-4" />
						<div
							class="d-flex next-btn-section"
							*ngIf="
								(!isAdminUser && !isProjectManagerUser) ||
								(isProjectManagerUser && bCopyBrief)
							">
							<div>
								<button
									class="btn draft-project-btn mb-4"
									(click)="saveAsDraft($event)"
									[disabled]="bShowProgressBar || bDisableBtn"
									*ngIf="
										!isDraftDisbaled &&
										!isProjectManagerUser &&
										oProject?.project_status_id != 1 &&
										action != 'redo-project' &&
										action != 'start-project'
									">
									Save Project as Draft
								</button>
								<button
									class="btn draft-project-btn mb-4"
									(click)="saveAsDraft($event)"
									*ngIf="
										!isDraftDisbaled &&
										!isProjectManagerUser &&
										oProject?.project_status_id != 1 &&
										action != 'redo-project' &&
										action == 'start-project'
									"
									[disabled]="
										bShowProgressBar || bDisableBtn
									">
									Update Draft
								</button>
							</div>

							<div>
								<button
									class="btn start-project-btn next-project-btn mb-4"
									type="submit"
									(click)="
										submitMarketplaceProject();
										_analyticsService.setOverlayTrackData(
											17,
											108,
											oProjectsData?.project?.project_id,
											2
										)
									">
									<span>Next</span>
								</button>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<div *ngIf="!isAdminUser && !isMarketplaceTab">
					<hr />
					<div>
						<div class="my-4">
							<div
								*ngIf="
									(isOperationalClientUser ||
										isProjectManagerUser) &&
									pendingProjectsCountArr &&
									pendingProjectsCountArr.length > 0 &&
									!isEligibleActive
								">
								<div
									class="p mb-2"
									*ngIf="pendingProjectsCount != 0">
									You currently have
									{{ pendingProjectsCount }} pending
									{{
										pendingProjectsCount == 1
											? 'project'
											: 'projects'
									}}. Please select the order for this project
									to start.
								</div>
								<div
									class="p mb-2"
									*ngIf="pendingProjectsCount == 0">
									You don't have any pending projects. This
									project will start as soon as the active
									slot is available.
								</div>
								<div
									class="d-flex align-items-baseline"
									*ngIf="pendingProjectsCount != 0">
									<label class="text-bold">
										Order: &nbsp;
									</label>
									<select
										class="form-control mr-2 width-90"
										(change)="onChangeSortOrder($event)"
										*ngIf="
											pendingProjectsCountArr?.length >
												0 && !isEligibleActive
										">
										<option
											[value]="j + 1"
											*ngFor="
												let project of pendingProjectsCountArr;
												let j = index
											"
											[selected]="
												j ==
												pendingProjectsCountArr?.length -
													1
											">
											{{ j + 1 }}
										</option>
									</select>
								</div>
							</div>
						</div>
						<div
							*ngIf="
								oProject?.is_category_plan == false &&
								!hasMarketplaceAccess &&
								action == 'start-project'
							">
							<p>
								This type of project is not included in your
								project. Upgrade now to start it.
							</p>
						</div>
						<div>
							<div
								class="d-flex align-items-baseline flex-wrap start-project-btn-section"
								*ngIf="
									(!isAdminUser && !isProjectManagerUser) ||
									(isProjectManagerUser && bCopyBrief)
								">
								<ng-container
									*ngIf="
										action === 'update-brief';
										else projectActions
									">
									<button
										(click)="saveAsDraft($event)"
										class="btn btn-purple px-4">
										Save Project Brief
									</button>
								</ng-container>
								<div
									*ngIf="action !== 'update-brief'"
									class="d-inline-block dropdown-actions-container custom-select-ngx"
								>
									<ngx-select
										class="text-muted clickable"
										[items]="actionItems"
										optionValueField="id"
										optionTextField="label"
										placeholder="Select An Action"
										(select)="onDropdownActionChange($event)"
										[ngClass]="{
											'select-start-project': selectedAction === 'Start Project', 
											'select-save-draft': selectedAction === 'Save Project As Draft', 
											'select-update-draft': selectedAction === 'Update Draft', 
											'select-save-template': selectedAction === 'Save Project As Template', 
											'select-save-new-template': selectedAction === 'Save Project As New Template', 
											'select-update-template': selectedAction === 'Update Template'
										}"
									>
									</ngx-select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="upgrade-plan-info">
				<div class="d-flex justify-content-center" *ngIf="this.oDefs">
					<div
						*ngIf="
							hasMarketplaceAccess &&
							oProjectsData?.project?.concept_id !=
								projectConcepts?.marketplace &&
							!isSubClientUser &&
							!bDisableType &&
							!isMarketplaceTab &&
							!(
								authSeamLessData?.plan_activated &&
								isUpdatedBilling &&
								!isUserOnPausePlan
							)
						"
						class="upgrade-plan-model d-flex flex-wrap flex-wrap">
						<div class="card">
							<div class="card-body d-flex">
								<div class="plan-text-wrapper">
									<p
										class="f-24"
										*ngIf="
											authSeamLessData?.first_paid_at &&
											(authSeamLessData?.plan_activated ||
												(!authSeamLessData?.is_plan_cancelled &&
													!authSeamLessData?.projects_exists))
										">
										If you're looking for unlimited
										projects, update your billing info now
										to gain access to all our incredible
										features.
									</p>
									<p class="f-24" *ngIf="isUserOnPausePlan">
										If you're looking for unlimited
										projects, reactivate your account now to
										gain access to all our incredible
										features.
									</p>
									<p
										class="f-24"
										*ngIf="
											authSeamLessData?.first_paid_at &&
											hasMarketplaceAccess &&
											(authSeamLessData?.is_plan_cancelled ||
												authSeamLessData?.projects_exists)
										">
										If you're looking for unlimited
										projects, renew your subscription now to
										gain access to all our incredible
										features.
									</p>
									<p
										class="f-24"
										*ngIf="
											!authSeamLessData?.first_paid_at &&
											hasMarketplaceAccess
										">
										If you're looking for unlimited
										projects, purchase your subscription now
										to gain access to all our incredible
										features.
									</p>
									<p class="f-24" *ngIf="!isUpdatedBilling">
										If you're looking for unlimited
										projects, update your billing info now
										to gain access to all our incredible
										features.
									</p>
									<button
										class="mb-0 text-uppercase btn upgrade-btn w-50"
										*ngIf="
											authSeamLessData?.first_paid_at &&
											(authSeamLessData?.plan_activated ||
												(!authSeamLessData?.is_plan_cancelled &&
													!authSeamLessData?.projects_exists))
										"
										(click)="redirectForUpgradePlan()">
										Upgrade
									</button>
									<button
										*ngIf="isUserOnPausePlan"
										class="btn upgrade-btn w-50"
										(click)="redirectForUpgradePlan()">
										Reactivate
									</button>
									<button
										*ngIf="
											authSeamLessData?.first_paid_at &&
											hasMarketplaceAccess &&
											(authSeamLessData?.is_plan_cancelled ||
												authSeamLessData?.projects_exists)
										"
										class="btn upgrade-btn w-50"
										(click)="redirectForUpgradePlan()">
										Renew
									</button>
									<button
										*ngIf="
											!authSeamLessData?.first_paid_at &&
											hasMarketplaceAccess
										"
										class="btn upgrade-btn w-50"
										(click)="redirectForUpgradePlan()">
										Purchase
									</button>
									<button
										*ngIf="!isUpdatedBilling"
										class="btn upgrade-btn w-50"
										(click)="redirectForUpgradePlan()">
										Update Billing Info
									</button>
								</div>
								<div
									class="plan-img-wrapper w-50 d-flex justify-content-end">
									<img
										src="../../../../../assets/img/create_project/dummy-img.png"
										alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

<div class="d-flex justify-content-center" *ngIf="this.oDefs">
	<div
		*ngIf="
			false &&
			hasMarketplaceAccess &&
			oProjectsData?.project?.concept_id !=
				projectConcepts?.marketplace &&
			!isSubClientUser &&
			!bDisableType &&
			oDefs['marketplaceTitles']?.length &&
			!(
				authSeamLessData?.plan_activated &&
				isUpdatedBilling &&
				!isUserOnPausePlan
			)
		"
		class="upgrade-plan-model d-flex flex-wrap flex-wrap">
		<div class="card">
			<div class="card-body d-flex">
				<div class="plan-text-wrapper">
					<p
						class="f-24"
						*ngIf="
							!isUserOnPausePlan &&
							isUpdatedBilling &&
							!authSeamLessData?.is_plan_cancelled
						">
						If you're looking for unlimited projects, update your
						billing info now to gain access to all our incredible
						features.
					</p>
					<p class="f-24" *ngIf="isUserOnPausePlan">
						If you're looking for unlimited projects, reactivate
						your account now to gain access to all our incredible
						features.
					</p>
					<p
						class="f-24"
						*ngIf="
							!authSeamLessData?.plan_activated &&
							isUpdatedBilling &&
							authSeamLessData?.is_plan_cancelled
						">
						If you're looking for unlimited projects, renew your
						subscription now to gain access to all our incredible
						features.
					</p>
					<p class="f-24" *ngIf="!isUpdatedBilling">
						If you're looking for unlimited projects, update your
						billing info now to gain access to all our incredible
						features.
					</p>
					<button
						class="mb-0 text-uppercase btn upgrade-btn"
						*ngIf="
							!isUserOnPausePlan &&
							isUpdatedBilling &&
							!authSeamLessData?.is_plan_cancelled
						"
						(click)="redirectForUpgradePlan()">
						Upgrade
					</button>
					<button
						*ngIf="isUserOnPausePlan"
						class="btn upgrade-btn"
						(click)="redirectForUpgradePlan()">
						Reactivate
					</button>
					<button
						*ngIf="
							!authSeamLessData?.plan_activated &&
							isUpdatedBilling &&
							authSeamLessData?.is_plan_cancelled
						"
						class="btn upgrade-btn"
						(click)="redirectForUpgradePlan()">
						Renew
					</button>
					<button
						*ngIf="!isUpdatedBilling"
						class="btn upgrade-btn"
						(click)="redirectForUpgradePlan()">
						Update Billing Info
					</button>
				</div>
				<div class="plan-img-wrapper w-50 d-flex justify-content-end">
					<img
						src="../../../../../assets/img/create_project/dummy-img.png"
						alt="" />
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #designerProfileModal>
	<div class="modal-header" *ngIf="oDesignerUser">
		<h4 class="modal-title text-primary">
			{{ oDesignerUser.name }}'s Profile
		</h4>
		<ng-container *ngIf="activeModalType == 'view_profile'">
			<div
				*ngIf="
					!oDesignerUser.is_suggested ||
					oDesignerUser.invite_exclude == 1
				">
				<ng-container
					*ngTemplateOutlet="
						showInviteExcludeModal;
						context: { oDesignerUser: oDesignerUser }
					"></ng-container>
			</div>
			<div
				*ngIf="
					oDesignerUser.is_suggested &&
					oDesignerUser.invite_exclude != 1
				">
				<ng-container
					*ngTemplateOutlet="
						showInviteCheckboxModal;
						context: { oDesignerUser: oDesignerUser }
					"></ng-container>
			</div>
		</ng-container>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalDesignerProjectRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body p-3" *ngIf="oDesignerUser">
		<div class="container container-xl p-0">
			<app-designer-profile
				[user_id]="oDesignerUser?.id"
				[client_id]="
					isProjectManagerUser && bCopyBrief
						? oProject?.created_by
						: ''
				"
				[profilePath]="oDesignerUser?.profile_pic"
				[projectTypeId]="category?.type"
				[projectType]="selectedProjectType"
				[trigger_id]="'1'"
				[isEligibleForHire]="isEligibleForHire"></app-designer-profile>
		</div>
	</div>
</ng-template>
<ng-template
	#showInviteExclude
	let-oTeamMember="oTeamMember"
	let-showExlude="showExlude">
	<div class="disabled-content-wrapper">
		<button
			class="btn btn-primary mr-1 mb-2"
			[ngClass]="
				aExcludedTeamMembers.indexOf(oTeamMember.id) > -1
					? 'disabled'
					: ''
			"
			(click)="
				onClickAddTeamMember(
					'step1',
					oTeamMember.id,
					oTeamMember?.creative_level_id
				)
			"
			*ngIf="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) == -1
			">
			Invite
		</button>
		<button
			class="btn btn-warning mr-1 mb-2"
			(click)="onClickRemoveTeamMember('step1', oTeamMember.id)"
			*ngIf="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) > -1
			">
			Invited
		</button>
		<button
			class="btn btn-primary mr-1 mb-2"
			(click)="
				initDesignerProjectModal(
					oTeamMember,
					designerProfileModal,
					3,
					'view_profile'
				)
			">
			View Profile
		</button>
	</div>
	<div class="mt-2 disabled-content-wrapper" *ngIf="showExlude">
		<a
			class="btn-xs text-underline text-danger"
			href="javascript:"
			(click)="onClickAddExcludeTeamMember(oTeamMember.id)"
			[ngClass]="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) > -1
					? 'disabled text-muted'
					: ''
			"
			*ngIf="aExcludedTeamMembers.indexOf(oTeamMember.id) == -1">
			Exclude
		</a>
		<a
			class="btn-xs text-underline text-danger"
			href="javascript:"
			(click)="onClickRemoveExcludeTeamMember(oTeamMember.id)"
			*ngIf="aExcludedTeamMembers.indexOf(oTeamMember.id) > -1">
			Excluded
		</a>
	</div>
</ng-template>

<ng-template #showInviteCheckboxModal let-oDesignerUser="oDesignerUser">
	<button
		class="btn btn-primary btn-sm ml-3"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) == -1
		"
		(click)="onClickAddTeamMember('step1', oDesignerUser.id)">
		Invite Team Member
	</button>
	<button
		class="btn btn-info btn-sm ml-3"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) > -1
		"
		(click)="onClickRemoveTeamMember('step1', oDesignerUser.id)">
		Uninvite Team Member
	</button>
</ng-template>

<ng-template #showInviteExcludeModal let-oDesignerUser="oDesignerUser">
	<button
		class="btn btn-outline-primary btn-sm ml-3 mr-1"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) == -1
		"
		(click)="onClickAddTeamMember('step1', oDesignerUser.id)">
		Invite Team Member
	</button>
	<button
		class="btn btn-primary btn-sm ml-3 mr-1"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) > -1
		"
		(click)="onClickRemoveTeamMember('step1', oDesignerUser.id)">
		Invited Team Member
	</button>
	<button
		class="btn btn-outline-danger btn-sm"
		(click)="onClickAddExcludeTeamMember(oDesignerUser.id)"
		*ngIf="aExcludedTeamMembers.indexOf(oDesignerUser.id) == -1">
		Exclude Team Member
	</button>
	<button
		class="btn btn-danger btn-sm"
		(click)="onClickRemoveExcludeTeamMember(oDesignerUser.id)"
		*ngIf="aExcludedTeamMembers.indexOf(oDesignerUser.id) > -1">
		Excluded Team Member
	</button>
</ng-template>
<ng-template #archiveProjectModal>
	<div class="modal-header">
		<h4 class="modal-title text-primary">{{ oProject.title }}</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalArchiveProjectRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<div class="text-bold">
				Are you sure you want to archive this project?
			</div>
		</div>
	</div>
	<div class="p-4">
		<div class="text-right">
			<a
				href="javascript:"
				class="text-underline mr-2"
				data-dismiss="modal"
				(click)="modalArchiveProjectRef.hide()">
				Cancel
			</a>
			<button
				type="button"
				class="btn btn-primary ml-2"
				data-dismiss="modal"
				(click)="onSubmitArchiveProject()">
				I am sure, archive project
			</button>
		</div>
	</div>
</ng-template>
<ng-template #initNotesModal>
	<app-notes
		[designer]="oDesignerUser"
		(emitCloseModal)="modalNotesRef.hide()"></app-notes>
</ng-template>

<ng-template #initStatsModal>
	<app-stats
		[designer]="oDesignerUser"
		(emitCloseStatsModal)="modalStatsRef.hide()"></app-stats>
</ng-template>

<app-project-payments
	[isPaymentModalOpen]="isPaymentModalOpen"
	[selectedPackage]="selectedPackage"
	[projectsData]="oProjectsData"
	[currentProject]="currentProject"
	(paymentProceed)="onProjectProceedSuccessful()"
	(closePaymentModel)="onClosePaymentModel()"
	*ngIf="isPaymentModalOpen"></app-project-payments>

<ng-template #packageDetailedInfo>
	<app-package-detailed-info
		(onClosePackageModal)="modalDesignerProjectRef.hide()"
		[packageInfo]="packageInfo"
		(onSelectPackage)="onSelectPackage($event)"
		[selectedPackage]="selectedPackage"></app-package-detailed-info>
</ng-template>

<ng-container *ngIf="!isSkeletonLoading">
	<app-project-loader></app-project-loader>
</ng-container>

<ng-template #saveAsTemplateModal>
	<div class="container pl-4 pr-4">
		<div class="modal-header pl-0 pr-0">
			<p class="modal-title f-24">
				Give your new template a name for future reference!
			</p>
			<img
				class="close clickable m-0 p-2 mt-1"
				[src]="'../../../../../' + closeIcon"
				alt=""
				(click)="modalSaveAsTemplateModal.hide()" />
		</div>
		<div class="modal-body pl-0 pr-0 pt-4 pb-4">
			<div class="container-fluid p-0">
				<div class="form-group">
					<div>
						<input
							autocomplete="off"
							class="form-control"
							[formControl]="templateName"
							name="templateName"
							placeholder="Type a keyword that best describes your project"
							type="text" />
						<div class="input-group-append"></div>
					</div>
				</div>
				<div class="row m-0">
					<div class="ml-auto">
						<button
							[disabled]="!templateName.valid"
							(click)="
								saveAsDraft($event, {
									isTemplate: true,
									method: 'create'
								});
								modalSaveAsTemplateModal.hide()
							"
							class="btn save-template-btn"
							type="submit">
							Save Template
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #updateTemplateModal>
	<div class="container pl-4 pr-4">
		<div class="modal-header pl-0 pr-0">
			<p class="modal-title f-24">Update template name</p>
			<img
				class="close clickable m-0 p-2 mt-1"
				[src]="'../../../../../' + closeIcon"
				alt=""
				(click)="modalUpdateTemplateModal.hide()" />
		</div>
		<div class="modal-body pl-0 pr-0 pt-4 pb-4">
			<div class="container-fluid p-0">
				<div class="form-group">
					<div>
						<input
							autocomplete="off"
							class="form-control"
							[formControl]="templateName"
							name="templateName"
							placeholder="Type a keyword that best describes your project"
							type="text" />
						<div class="input-group-append"></div>
					</div>
				</div>
				<div class="row m-0">
					<div class="ml-auto">
						<button
							[disabled]="!templateName.valid"
							(click)="
								saveAsDraft($event, {
									isTemplate: true,
									method: 'update'
								});
								modalUpdateTemplateModal.hide()
							"
							class="btn update-template-btn"
							type="submit">
							Update Template
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
